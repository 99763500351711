import { create } from "zustand";
import axios from 'axios';

export const useInviteStore = create((set) => ({
    currentInvite: null,
    invite: [],
    InviteUser: async (payload) => {
        try {
            console.log(payload);
            const token = localStorage.getItem('token');
            const response = await axios.post('http://20.197.8.121:6001/sendInvite', payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            });
            console.log('Invite sent successfully', response);
            return response
        } catch (err) {
            console.error('Failed to send invite:', err);
            throw err;
        }
    },

    InviteCreate: async (payload) => {
        try {
            const response = await axios.post('http://20.197.8.121:6001/token', payload);
            console.log('Invite sent successfully', response);
            return response;
        } catch (err) {
            console.error('Failed to send invite:', err);
            throw err;
        }
    },

    getInvite: async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(`http://20.197.8.121:6001/getTaskbyID/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('task fetched successfully',response );
            set({ invite: response.data })
        } catch (err) {
            console.log("failed to fetched", err);
            throw err;
        }
    },
    setCurrentInvite: (invites) => set({ currentInvite: invites })
}));
