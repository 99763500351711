import React, { useState, useEffect } from "react";
import Sidebar from "./SaidBar";
import SearchBar from "./SearchBar";
import MobileSaibar from "./MobileSaidbar";
import { Outlet } from "react-router-dom";
import BottomTab from './BottomTab'

const MainLayout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="md:flex h-screen">
      {isMobile ? (
        <div className="block lg:hidden ">
          <MobileSaibar />
        </div>
      ) : (
        <div className=" lg:block">
          <Sidebar className="w-64 bg-gray-200 p-4" />
        </div>
      )}

      <div className="md:w-full md:m-[17px] justify-center items-center flex-col">
        <SearchBar />
        <div className="">
          <Outlet />
          <BottomTab/>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
