import { create } from 'zustand';
import axios from "axios";

export const useMemberStore = create((set, get) => ({
    currentMember: null,

    getMemberBoard: async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`http://20.197.8.121:6001/getmember/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Board fetched successful:', response);
            return response.data;
        } catch (err) {
            console.error('Failed to fetch boards:', err);
            throw err;
        }
    },
    getMember:async()=>{
        const {currentMember}=get();
        if (!currentMember || !currentMember._id) {
            throw new Error("No current board selected");
        }
        const token = localStorage.getItem('token');
        const userId= localStorage.getItem('userId')
        try{
            const response= await axios.get(`http://20.197.8.121:6001/getboard/${currentMember._id}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        }catch(err){
            console.error('Failed to fetch board:', err);
            throw err;
        } 
    },
    setCurrentMember: (member) =>set({currentMember: member})
}))