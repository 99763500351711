import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useProjectStore } from '../Store/ProjectStore/ProjectStore';

const EditProjectModal = ({ isOpen, onClose, project, onSave }) => {
  const [projectName, setProjectName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { updateProject } = useProjectStore(state => ({
    updateProject: state.updateProject
  }));

  useEffect(() => {
    if (project) {
      setProjectName(project.projectname);
    }
  }, [project]);

  if (!isOpen) return null;

  const handleSave = async () => {
    if (!projectName) {
      setError('Project name is required');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await updateProject(project._id, projectName); // Pass project ID and name

      onSave(); // Notify parent component to refresh data
      onClose(); // Close the modal after successful update
    } catch (err) {
      console.error('Failed to update project:', err);
      setError('Failed to update project. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="modal-content bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <div className="modal-header flex justify-between items-center">
          <h3 className="modal-title text-lg font-semibold">Edit Project</h3>
          <button
            onClick={onClose}
            type="button"
            className="modal-close text-gray-500 hover:text-gray-700"
          >
            <IoMdClose size={25} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="modal-body mt-4">
          <div className="space-y-4">
            <p className="modal-description text-gray-600">
              Update the project name as needed.
            </p>
            <input
              type="text"
              name="projectName"
              id="projectName"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="modal-input border border-gray-300 rounded px-3 py-2 w-full"
              placeholder="Enter project name"
              required
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <div className="flex space-x-4 mt-4">
              <button
                type="button"
                onClick={handleSave}
                className={`modal-button-primary bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
              <button
                type="button"
                className="modal-button-secondary bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProjectModal;
