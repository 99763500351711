import React, { useState } from "react";
import { useNavigate, Link,useParams } from "react-router-dom";
import { useAccountStore } from "../Store/AccountStore/AccountStore";
import { useInviteStore } from "../Store/InviteStore/InviteMemberStore";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"; 


const Register = () => {
  const navigate = useNavigate();
  
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { registerUser } = useAccountStore();
  const { token } = useParams();

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Name validation
    if (!name.trim()) {
      newErrors.name = "Name is required.";
    } else if (name.length < 3) {
      newErrors.name = "Name must be at least 3 characters.";
    }

    // Contact validation
    if (!contact.trim()) {
      newErrors.contact = "Contact is required.";
    } else if (!/^\d+$/.test(contact)) {
      newErrors.contact = "Contact must be numeric.";
    } else if (contact.length < 10 || contact.length > 15) {
      newErrors.contact = "Contact must be between 10 to 15 digits.";
    }

    // Email validation
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Email is not valid.";
    }

    // Password validation
    if (!password) {
      newErrors.password = "Password is required.";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setErrors({}); // Reset error messages
  
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
  
    try {
      const hello = await registerUser({
        name,
        contact,
        email,
        password,
      });
      console.log("Register response:", hello); // Log the response
  
      if (hello && hello.status) {
        navigate("/otp", {
          state: { name, contact, email, password, token }, // Pass data as state
        });
      } else {
        setErrors({ general: "Registration failed. Please try again." });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        const { data } = error.response;
        const newErrors = {};
        if (data.message.includes("email")) {
          newErrors.email = "Email already exists.";
        }
        if (data.message.includes("contact")) {
          newErrors.contact = "Contact already exists.";
        }
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
      } else {
        setErrors({ general: "An error occurred. Please try again later." });
      }
    }
  };
  

  const onFailure = (error) => {
    alert("Error! Something went wrong...");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 px-4">
      <div className="p-8 w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl">
        <div className="flex justify-center items-center">
          <img
            src="/Whiteboard Logo PNG Black.png"
            className="w-[12rem] mb-9"
            alt="Whiteboard Logo"
          />
        </div>
        {errors.general && (
          <div className="text-red-500 mb-4">{errors.general}</div>
        )}
        <form onSubmit={handleRegister} className="space-y-4">
          <div className="space-y-2">
            <label className="block text-xl font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}
          </div>
          <div className="space-y-2">
            <label className="block text-xl font-medium text-gray-700">
              Contact
            </label>
            <input
              type="text"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              placeholder="Enter your contact number"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.contact && <p className="text-red-500">{errors.contact}</p>}
          </div>
          <div className="space-y-2">
            <label className="block text-xl font-medium text-gray-700">
              Email address
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="your@email.com"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>
          <div className="space-y-2 relative"> {/* Added relative position */}
            <label className="block text-xl font-medium text-gray-700">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="••••••••"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
            >
              {showPassword ? (
                <AiFillEyeInvisible className="text-gray-500"style={{marginTop:"22px"}} />
              ) : (
                <AiFillEye className="text-gray-500" style={{marginTop:"22px"}} />
              )}
            </button>
            {errors.password && (
              <p className="text-red-500">{errors.password}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-[#5845ee] text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Register
          </button>
          {/* <GoogleLogin onSuccess={handleLoginSuccess} onError={onFailure} useOneTap /> */}
        </form>
        <p className="w-full mt-2 text-gray-600 py-2 px-4 flex items-center justify-center">
          Already have an account?{" "}
          <Link to="/" className="text-blue-500 ml-1">
            Log in now.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
