import { create } from "zustand";
import axios from "axios";

export const useProjectStore = create((set, get) => ({
    currentProject: null,
    project: [],

    createProject: async (payload) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        try {
            const response = await axios.post(
                `http://20.197.8.121:6001/project/${userId}`, // Ensure this is your actual API endpoint
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Project created successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to create project:', err);
            throw err;
        }
    },

    getProject: async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }
        try {
            const response = await axios.get(
                `http://20.197.8.121:6001/getproject/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Project fetched successfully:', response.data);
            set({ project: response.data });
        } catch (err) {
            console.error('Failed to fetch project:', err);
            throw err;
        }
    },

    getProjectId:async(projectId)=>{
        const token= localStorage.getItem('token');
        console.log(projectId);
        try{
            const response= await axios.get(`http://20.197.8.121:6001/getprojectId/${projectId}`,{
                headers:{
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('project fetched ',response);
            return response.data;
        }catch(err){
            console.error('failed fetched project:',err);
            throw err;
        }
    },

    searchProject: async (projectname) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`http://20.197.8.121:6001/searchproject/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { projectname },
            });
            console.log(response);
            set({ project: response.data });
        } catch (err) {
            console.error('Failed to fetch team:', err);
            throw err;
        }
    },


    updateProject: async (projectId, projectName) => {
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        if (!projectId || !projectName) {
            throw new Error('Project ID or project name is missing.');
        }

        try {
            const response = await axios.put(
                `http://20.197.8.121:6001/updateproject/${projectId}`, // Ensure this is your actual API endpoint
                { projectname: projectName }, // Send the updated project name
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Project updated successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to update project:', err);
            throw err;
        }
    },


    deleteProject: async (projectId) => {
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        if (!projectId) {
            throw new Error('Project ID is required.');
        }

        try {
            const response = await axios.delete(
                `http://20.197.8.121:6001/deleteproject/${projectId}`, // Ensure this is your actual API endpoint
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Project deleted successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to delete project:', err);
            throw err;
        }
    },



    setCurrentProject: (project) => set({ currentProject: project }), // Add method to set the current project
}));
