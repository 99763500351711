import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAccountStore } from "../Store/AccountStore/AccountStore";
import { useInviteStore } from "../Store/InviteStore/InviteMemberStore";

const Otp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { token } = useParams(); // Access the token from URL
  const { otpVerify } = useAccountStore();
  const { InviteCreate } = useInviteStore();

  // Destructure the data passed from the Register component
  const { name, contact, email, password,token } = location.state || {};

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const handleOtpVerification = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    try {
      if (token) {
        // If token is present, call InviteUser API
        const inviteResult = await InviteCreate({
          name,
          contact,
          email,
          password,
          email_otp: otp,
          token // Pass OTP for verification
        });
        console.log(inviteResult)
        console.log(inviteResult.status)
        if (inviteResult.status) {
          navigate("/"); // Redirect on successful invite
        } else {
          setError("Incorrect OTP or invite failed.");
        }
      } else {
        // Otherwise, proceed with normal OTP verification
        const otpResult = await otpVerify({
          email_otp: otp,
        });

        if (otpResult.status) {
          navigate("/"); // Redirect on successful verification
        } else {
          setError("Incorrect OTP");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 px-4">
      <div className="p-8 w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl">
        <div className="flex justify-center items-center">
          <img src="/Whiteboard Logo PNG Black.png" className="w-[12rem] mb-9" alt="Logo" />
        </div>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleOtpVerification} className="space-y-4">
          <div className="space-y-2">
            <label className="block text-xl font-medium text-gray-700">OTP</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter your OTP"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#5845ee] text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Verify OTP & Create Account
          </button>
        </form>
      </div>
    </div>
  );
};

export default Otp;
