import moment from 'moment';
import React, { useState } from 'react';
import { CgPlayListAdd } from "react-icons/cg";
import { FiMoreVertical, FiEdit, FiTrash, FiUser } from 'react-icons/fi';
import axios from 'axios';
import EditTaskModel from './EditTaskModel';
import { useTaskStore } from '../Store/TaskStore/TaskStore';
import AssignUserModal from './AssignUserModal';
const Card = ({ item, fetchTasks }) => {

  const [showDropdown, setShowDropdown] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { deleteTask } = useTaskStore();
  const openEditModal = () => {
    setIsEditModalOpen(true);
    setShowDropdown(false);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };
  const handleDelete = async (taskId) => {
    try {
      await deleteTask(taskId);
      fetchTasks(); // Refresh the tasks after deletion
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };
  const openAssignModal = () => {
    setIsAssignModalOpen(true);
    setShowDropdown(false); // Close the dropdown when the modal is opened
  };

  const closeAssignModal = () => {
    setIsAssignModalOpen(false);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
const name=localStorage.getItem('name')
  console.log(item);
  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center w-full">
          
          <div className="relative">
            <FiMoreVertical size={20} className="dropdown-icon" onClick={toggleDropdown} />
            {showDropdown && (
              <div className="card-dropdown">
                <button className="card-dropdown-button" onClick={openEditModal}>
                  <FiEdit className="mr-2" /> Edit
                </button>
                <button className="card-dropdown-button" onClick={() => handleDelete(item._id)}>
                  <FiTrash className="mr-2" /> Delete
                </button>
                <button className="card-dropdown-button" onClick={openAssignModal}>
                  <FiUser className="mr-2" /> Assign
                </button>
              </div>
            )}
          </div>
        </div>

      </div>
      <div className="card-body">
        <h3 className="card-title">{item.name}</h3>
        <p className="comments font-semibold">{item.subject}</p>
        <p className="comments">{item.description}</p>
        <p className="text-gray-600" style={{ paddingTop: '4px' }}>Created by: {name}</p>
      </div>

      {isEditModalOpen && (
        <EditTaskModel
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          taskToEdit={item}
          onUpdateTask={fetchTasks}
        />
      )}
      {isAssignModalOpen && (
        <AssignUserModal
          visible={isAssignModalOpen}
          onClose={closeAssignModal}
          taskId={item._id}
        />
      )}
      <div className="card-footer text-right mt-[3rem]">
        <span className="date text-xs text-gray-500">
          {moment(item.createdAt).format('LLL')}
        </span>
      </div>
    </div>
  );
};

const MobileKanbanBoard = (props) => {
  const { tasks, fetchTasks, columns } = props
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleTasks, setVisibleTasks] = useState({ 'To-Do List': true });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const choices = {
    todo: 'To-Do',
    onProgress: 'On-Progress',
    done: 'Done',
    hold: 'Hold'
  }



  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddTask = (newTask) => {

  };

  const toggleTaskVisibility = (category) => {
    setVisibleTasks((prevVisibleTasks) => ({
      ...prevVisibleTasks,
      [category]: !prevVisibleTasks[category],
    }));
  };

  return (
    <>
      <div className="kanban-board md:hidden">
        {/* Add Task Button */}
        <div className="kanban-board-header" onClick={handleOpenModal}>
          <div className="kanban-board-add-button Reshidden">
            <CgPlayListAdd size={25} className="" />
            <button>Add Task</button>
          </div>
        </div>

        {/* Kanban Board Tasks */}
        <div className="kanban-board-grid">
          {columns.map((column) => (
            <div key={column.id}>
              <div className={`kanban-board-column-header ${column.color}`} onClick={() => toggleTaskVisibility(column.category)}>
                <div className="flex justify-between">
                  <div>
                    <h2 className="font-semibold text-lg">{column.title} ({column.count})</h2>
                    <p className="text-sm">{column.desc}</p>
                  </div>
                  <div>
                    <FiMoreVertical size={20} className="dropdown-icon" />
                  </div>
                </div>
              </div>
              {visibleTasks[column.category] && (
                <div className="kanban-board-column-body">
                  {tasks
                    .filter(task => task.status === choices[column.id])
                    .sort((a, b) => a.index - b.index)  // Sort by index to maintain order
                    .map((item, itemIndex) => (
                      <Card key={item._id} item={item} fetchTasks={fetchTasks} index={itemIndex} />
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobileKanbanBoard;
