import { create } from "zustand";
import axios from "axios";

export const useAccountStore = create((set) => ({
  currentUser: null, members: [],

  loginUser: async (payload) => {
    try {
      const response = await axios.post('http://20.197.8.121:6001/login', payload);
      console.log(response);

      if (response.status === 200) { // Check if the status is 200 (OK)
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.data); // Assuming the user ID is in response.data.userId

        // You can also update the currentUser in the state
        set({ currentUser: response.data.user });
      }

      return response;
    } catch (err) {
      console.log(err);
    }
  },
  registerUser: async (payload) => {
    try {
      const response = await axios.post('http://20.197.8.121:6001/otp', payload);
      console.log(response);
      return response
    } catch (err) {
      console.log(err);
    }
  },
  otpVerify: async (payload) => {
    try {
      const response = await axios.post('http://20.197.8.121:6001/signup', payload);
      console.log(response);
      return response
    } catch (err) {
      console.log(err);
    }
  },

  googleLogin: async (Token) => {
    set({ loading: true, error: null });
    try {
      const response = await axios.post(`http://20.197.8.121:6001/verify-token`, { Token });
      console.log(response);
      if (response.data.success) {
        set({ user: response.data.user, isAuthenticated: true, loading: false });
      } else {
        set({ error: response.data.message, loading: false, isAuthenticated: true });
      }
    } catch (error) {
      set({ error: 'failed to authenticate', loading: false })
    }
  },
  userProfile: async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        " http://20.197.8.121:6001/profile",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('user fetched successfully', response);
      return response.data;


    } catch (err) {
      console.error('failed to fetch user:', err);
      throw err;
    }
  },
  passwordChange: async (payload) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put('http://20.197.8.121:6001/change-password', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('password updated successfully', response);
      return response.data;
    } catch (err) {
      console.error('failed to update', err);
      throw err;
    }
  },
  subUser: async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        " http://20.197.8.121:6001/sub-user",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('user fetched successfully', response);
      return response.data;
    } catch (err) {
      console.error('failed to fetch user:', err);
      throw err;
    }
  },
  setSubUser: async (payload) => {
    set({ members: payload })

  },
  userName: async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(
        ` http://20.197.8.121:6001/sub/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('user fetched successfully', response);
      return response.data;
    } catch (err) {
      console.error('failed to fetch user:', err);
      throw err;
    }
  }

}));
