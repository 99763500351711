import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './CustomNode.css';

const CustomNode = ({ id, data, isConnectable }) => {
  const [label, setLabel] = useState(data.label);

  const handleChange = (event) => {
    const newLabel = event.target.value;
    setLabel(newLabel);
    data.label = newLabel;
  };

  const getShapeStyle = () => {
    switch (data.shape) {
      case 'oval':
        return { borderRadius: '50%', width: '100px', height: '60px' };
      case 'diamond':
        return { transform: 'rotate(45deg)', width: '80px', height: '80px' };
      case 'rectangle':
      default:
        return { borderRadius: '5px', width: '120px', height: '60px' };
    }
  };

  const handleStyles = (position) => {
    const style = {
      top: position === 'top' ? '10%' : 'auto',
      bottom: position === 'bottom' ? '10%' : 'auto',
      left: position === 'left' ? '10%' : 'auto',
      right: position === 'right' ? '10%' : 'auto',
    };
    if (data.shape === 'diamond') {
      style.transform = 'rotate(-45deg)';
    }
    return style;
  };

  return (
    <div
      className={`custom-node ${data.shape}-node`}
      style={{
        position: 'relative',
        padding: '0',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        ...getShapeStyle(),
      }}
    >
      <input
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          textAlign: 'center',
          backgroundColor: 'transparent',
          padding: 0,
          margin: 0,
          transform: data.shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        }}
        value={label}
        onChange={handleChange}
      />
      <Handle type="target" position="top" style={handleStyles('top')} isConnectable={isConnectable} />
      <Handle type="source" position="bottom" style={handleStyles('bottom')} isConnectable={isConnectable} />
    </div>
  );
};

export const TaskNode = (props) => <CustomNode {...props} data={{ ...props.data, shape: 'rectangle' }} />;
export const DecisionNode = (props) => <CustomNode {...props} data={{ ...props.data, shape: 'diamond' }} />;
export const OvalNode = (props) => <CustomNode {...props} data={{ ...props.data, shape: 'oval' }} />;

export default CustomNode;
