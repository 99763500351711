import React, { useState, useEffect } from "react";
import { IoSearch } from "react-icons/io5";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import MobileMemberTable from "./MobileMemberTable";
import { useAccountStore } from "../Store/AccountStore/AccountStore";

const Users = () => {
  const [users, setUsers] = useState([]); // Ensure users is initialized as an empty array
  const { subUser } = useAccountStore();

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await subUser();
        setUsers(response);
        console.log("Users fetched successfully", response);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, [subUser]);

  return (
    <>
      <div className="min-h-screen bg-gray-100 p-4 Reshidden">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <header className="header-container">
            <h1 className="header-title Reshidden">Members</h1>
            <div className="create-project-button Reshidden">
              <IoMdAdd size={20} />
              <button className="font-semibold">Invite members</button>
            </div>
          </header>

          <div className="bg-blue-600 text-white p-3 rounded mb-4">
            <span>{users.length} member(s) below is in the team.</span>
          </div>

          <div className="search-container">
            <IoSearch size={25} />
            <input
              type="text"
              placeholder="Search Members"
              className="search-input"
            />
          </div>

          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr className="table-header">
                  <th className="table-header-cell">Name</th>
                  <th className="table-header-cell">Created Date</th>
                  <th className="table-header-cell">Created By</th>
                  {/* <th className="table-header-cell">Boards</th>
                  <th className="table-header-cell">Status</th> */}
                  <th className="table-header-cell">Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id} className="table-row">
                    <td className="space-y-2 flex items-center">
                      <div className="avatar">
                        <span className="avatar-text">{user.name.charAt(0)}</span>
                      </div>
                      <div>
                        <p className="font-semibold">
                          {user.name}{" "}
                          {user.user_type === 0 && (
                            <span className="text-sm text-blue-500">(Admin)</span>
                          )}
                        </p>
                        <p className="text-gray-500">{user.email}</p>
                      </div>
                    </td>
                    <td className="table-cell">{moment(user.created_at).format("LLL")}</td>
                    <td className="table-cell">
                      {user.user_type === '0' ? "Admin" : "User"}
                    </td>
                    {/* <td className="table-cell">{user.boardsAccess}</td> */}
                    {/* <td>
                      <button
                        className={`button ${
                          user.is_active === "Active"
                            ? "bg-green-600 text-white"
                            : "bg-gray-400 text-white"
                        }`}
                      >
                        {user.status}
                      </button>
                    </td> */}
                    <td className="table-cell flex space-x-2">
                      <button className="actions-button">
                        <FiEdit size={20} />
                      </button>
                      <button className="delete-button">
                        <FiTrash2 size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <MobileMemberTable />
    </>
  );
};

export default Users;
