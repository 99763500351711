import { create } from 'zustand';
import axios from 'axios';

export const useTaskStore = create((set, get) => ({
    currentTask: null,
    tasks: [],
    // Create a new task
    createTask: async (payload) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        try {
            const response = await axios.post(
                `http://20.197.8.121:6001/task/${userId}`, // Ensure this is your actual API endpoint
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Task created successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to create task:', err);
            throw err;
        }
    },

    // Fetch all tasks
    getTasks: async () => {
        const projectId = localStorage.getItem('ProjectId');
        const userId=localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`http://20.197.8.121:6001/getTask/${userId}/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            set({ tasks: response.data });
        } catch (error) {
            console.error('Failed to fetch tasks:', error);
            throw error;
        }
    },

    // Update an existing task
    updateTask: async (payload) => {
        const { currentTask } = get();
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        if (!currentTask || !currentTask._id) {
            throw new Error('Current team or team ID is missing.');
        }
        try {
            const response = await axios.put(
                `http://20.197.8.121:6001/updateTask/${currentTask._id}`, // Ensure this is your actual API endpoint
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Task updated successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to update task:', err);
            throw err;
        }
    },

    // Delete a task
    deleteTask: async (taskId) => {
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        if (!taskId) {
            throw new Error('Task ID is required.');
        }

        try {
            const response = await axios.delete(
                `http://20.197.8.121:6001/deleteTask/${taskId}`, // Ensure this is your actual API endpoint
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Task deleted successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to delete task:', err);
            throw err;
        }
    },

    dragTask: async (payload) => {

        const token = localStorage.getItem('token');
        const id = localStorage.getItem('draggableId')

        if (!token) {
            throw new Error('Authorization token is missing.');
        }
        try {
            const response = await axios.put(
                `http://20.197.8.121:6001/updatestatus/${id}`, payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Task updated successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to update task:', err);
            if (err.response && err.response.status === 401) {
                console.error('Unauthorized access. Please check your token.');
                // Optionally handle re-authentication
            }
            throw err;
        }
    },

    assignTask: async (payload) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(
                `http://20.197.8.121:6001/assign`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Task assigned successfully:', response.data);
            return response.data; // Return the response data on success
        } catch (err) {
            console.log("failed to assigned user",  err);
            throw err; // Return the error data
        }
    },
    

    setCurrentTask: (task) => set({ currentTask: task }),
}));
