import React from "react";
// import Login from "./components/Login";
import { Route, Routes } from "react-router-dom";
import CreateBoard from "./components/CreateBoard";
import MainLayout from "./components/MainLayout";
import Users from "./components/Users";
import Login from "./components/Login";
import Whiteboard from "./components/Whiteboard";
import Team from "./components/Team";
import ProfilePage from "./components/ProfilePage";
import Projects from "./components/Projects";
import KanbanBoard from "./components/KanbanBoard";
import './App.css'
import Process from "./components/flowchart/process/Process";
import ProgramFlowChart from "./components/flowchart/program/Program";
import SwimlaneDiagram from "./components/flowchart/swimlance/Swimlance";
import SystemFlowDiagram from "./components/flowchart/system/System";
import Workflow from "./components/flowchart/workflow/Workflow";
import EPCFlowChart from "./components/flowchart/EPC/EPC";
import Decision from "./components/flowchart/decision/Decision";
import DataFlowDiagram from "./components/flowchart/dataflow/Dataflow";
import CrossFunctionalFlowChart from "./components/flowchart/crossfuntional/CrossFuntional";
import BPMNFlowChart from "./components/flowchart/BPMN/BPMN";
import Flowchart from "./components/flowchart/Flowchart";
import Register from "./components/Register";
import Otp from "./components/Otp";



function App() {
  return (

    <div>

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/register/:token?" element={<Register/>}/>
        <Route path="/mainlayout/*" element={<MainLayout />}>
          {/* MainLayout handles nested routes for Dashboard */}
          <Route index element={<CreateBoard />} /> {/* Default route */}
          <Route path="members" element={<Users />} />
          <Route path="teams" element={<Team />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="projects" element={<Projects />} />
          <Route path="boards" element={<KanbanBoard />} />
        </Route>
        <Route path="/whiteboard" element={<Whiteboard />} />
        <Route path="/whiteboard/:id" element={<Whiteboard />} />

        <Route path="/flowchart/:id" element={<Flowchart/>}/>

        <Route path="/process" element={<Process/>}/>
        <Route path="/program" element={<ProgramFlowChart/>}/>
        <Route path="/swimlance" element={<SwimlaneDiagram/>}/>
        <Route path="/system" element={<SystemFlowDiagram/>}/>
        <Route path="/workflow" element={<Workflow/>}/>
        <Route path="/EPC" element={<EPCFlowChart/>}/>
        <Route path="/decision" element={<Decision/>}/>
        <Route path="/dataflow" element={<DataFlowDiagram/>}/>
        <Route path="/crossfuntional" element={<CrossFunctionalFlowChart/>}/>
        <Route path="/BPMN" element={<BPMNFlowChart/>}/>
      </Routes>
     
    </div>
    
  );
}

export default App;

// import React from "react";
// // import Login from "./components/Login";
// import { Route, Routes } from "react-router-dom";
// import CreateBoard from "./components/CreateBoard";
// import MainLayout from "./components/MainLayout";
// import Users from "./components/Users";
// import Login from "./components/Login";
// import MobileResponsiveTable from "./components/MobileResponsiveTable";
// import Whiteboard from "./components/Whiteboard";
// import Flowchart from './components/flowchart/Flowchart'
// import Team from "./components/Team";
// import ProfilePage from "./components/ProfilePage";
// import Projects from "./components/Projects";
// import KanbanBoard from "./components/KanbanBoard";
// import './App.css'
// import Process from "./components/flowchart/process/Process";
// import ProgramFlowChart from "./components/flowchart/program/Program";
// import SwimlaneDiagram from "./components/flowchart/swimlance/Swimlance";
// import SystemFlowDiagram from "./components/flowchart/system/System";
// import Workflow from "./components/flowchart/workflow/Workflow";
// import EPCFlowChart from "./components/flowchart/EPC/EPC";
// import Decision from "./components/flowchart/decision/Decision";
// import DataFlowDiagram from "./components/flowchart/dataflow/Dataflow";
// import CrossFunctionalFlowChart from "./components/flowchart/crossfuntional/CrossFuntional";
// import BPMNFlowChart from "./components/flowchart/BPMN/BPMN";

// function App() {
//   return (
//     <div>

//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route path="/mainlayout/*" element={<MainLayout />}>
//           {/* MainLayout handles nested routes for Dashboard */}
//           <Route index element={<CreateBoard />} /> {/* Default route */}
//           <Route path="members" element={<Users />} />
//           <Route path="teams" element={<Team />} />
//           <Route path="profile" element={<ProfilePage />} />
//           <Route path="projects" element={<Projects />} />
//           <Route path="boards" element={<KanbanBoard />} />
//         </Route>
//         <Route path="/whiteboard" element={<Whiteboard />} />
//         <Route path="/whiteboard/:id" element={<Whiteboard />} />
        
//         <Route path="/process" element={<Process/>}/>
//         <Route path="/process/:id" element={<Process/>}/>

//         <Route path="/program" element={<ProgramFlowChart/>}/>
//         <Route path="/program/:id" element={<ProgramFlowChart/>}/>

//         <Route path="/swimlance" element={<SwimlaneDiagram/>}/>
//         <Route path="/swimlance/:id" element={<SwimlaneDiagram/>}/>
      
//         <Route path="/system" element={<SystemFlowDiagram/>}/>
//         <Route path="/system/:id" element={<SystemFlowDiagram/>}/>
        
//         <Route path="/workflow" element={<Workflow/>}/>
//         <Route path="/workflow/:id" element={<Workflow/>}/>
        
//         <Route path="/EPC" element={<EPCFlowChart/>}/>
//         <Route path="/EPC/:id" element={<EPCFlowChart/>}/>

//         <Route path="/decision" element={<Decision/>}/>
//         <Route path="/decision/:id" element={<Decision/>}/>

//         <Route path="/dataflow" element={<DataFlowDiagram/>}/>
//         <Route path="/dataflow/:id" element={<DataFlowDiagram/>}/>
        
//         <Route path="/crossfuntional" element={<CrossFunctionalFlowChart/>}/>
//         <Route path="/crossfuntional/:id" element={<CrossFunctionalFlowChart/>}/>

//         <Route path="/BPMN" element={<BPMNFlowChart/>}/>
//       <Route path="/BPMN" element={<BPMNFlowChart/>}/>
//       </Routes>
//       {/* <Users /> */}
//     </div>
//   );
// }

// export default App;
