import React, { useState, useCallback } from 'react';
import { useFlowchartStore } from '../../../Store/FlowchartStore/FlowchartStore';
import ReactFlow, {
  addEdge,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import MergedNode from './MargedNode'; // Ensure this component is designed to handle different shapes
import { FiSquare } from 'react-icons/fi'; 

import { GoDiamond } from 'react-icons/go';
import { FaLink } from 'react-icons/fa';
import { FaRegCircle, FaSave, FaTrash } from 'react-icons/fa';
import './BPMN.css'; 
const nodeTypes = {
  node: MergedNode,
};

const initialElements = [
  { id: '1', type: 'node', data: { label: 'Start', shape: 'oval' }, position: { x: 100, y: 0 } },
  { id: '2', type: 'node', data: { label: 'Task 1', shape: 'rectangle' }, position: { x: 100, y: 100 } },
  { id: '3', type: 'node', data: { label: 'Gateway', shape: 'rectangle' }, position: { x: 100, y: 200 } },
  { id: '4', type: 'node', data: { label: 'End', shape: 'oval' }, position: { x: 100, y: 300 } },
  { id: 'e1-2', source: '1', target: '2', animated: true },
  { id: 'e2-3', source: '2', target: '3', animated: true },
  { id: 'e3-4', source: '3', target: '4', animated: true },
];

const BPMNFlowChart = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialElements.filter(el => !el.source && !el.target));
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialElements.filter(el => el.source && el.target));
  const { createChart } = useFlowchartStore();
  
  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  
  const [selectedShape, setSelectedShape] = useState('rectangle');
  const projectId = localStorage.getItem('ProjectId');
  
  const saveToAPI = async () => {
    try {
      const flowchart = { nodes, edges };
      await createChart({ flowcharts: flowchart, projectId, type: 'BPMN' });
      alert('Flowchart saved successfully');
    } catch (error) {
      console.error('Failed to save flowchart to API', error);
    }
  };

  const addNode = (shape) => {
    const newNode = {
      id: `${nodes.length + 1}`,
      type: 'node',
      data: { label: `Process ${nodes.length + 1}`, shape: shape || selectedShape },
      position: { x: 100, y: nodes.length * 150 },
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const addEdgeDynamically = () => {
    if (nodes.length < 2) {
      alert('Add at least two nodes to create an edge.');
      return;
    }
    const newEdge = {
      id: `e${nodes.length - 1}-${nodes.length}`,
      source: `${nodes.length - 1}`,
      target: `${nodes.length}`,
      animated: true,
    };
    setEdges((eds) => eds.concat(newEdge));
  };

  const removeSelectedElements = () => {
    
    const selectedNodes = nodes.filter((node) => node.selected);
    const selectedEdges = edges.filter((edge) => edge.selected);

    if (selectedNodes.length === 0 && selectedEdges.length === 0) {
      alert('Select a node or edge to remove.');
      return;
    }
    const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
    const updatedNodes = nodes.filter((node) => !node.selected);

    const updatedEdges = edges.filter(
      (edge) =>
        !selectedEdges.includes(edge) &&
        !selectedNodeIds.has(edge.source) &&
        !selectedNodeIds.has(edge.target)
    );
  
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  return (
    <div style={{ height: '100vh', position: 'relative', overflow: 'hidden' }}>
      <style>
        {`
          .react-flow__attribution {
            display: none;
          }
        `}
      </style>

      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          nodeTypes={nodeTypes}
          deleteKeyCode={46} // 'delete'-key
        >
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>

      <div style={{ position: 'absolute', top: 10, right: 10, zIndex: 9999, display: 'flex', gap: '10px' }}>
        <button
          onClick={() => addNode('rectangle')}
          style={{ padding: '8px', borderRadius: '50%', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          title="Add Rectangle Node"
        >
          <FiSquare size={20} />
        </button>
      
        <button
          onClick={() => addNode('oval')}
          style={{ padding: '8px', borderRadius: '50%', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          title="Add Oval Node"
        >
          <FaRegCircle size={20} />
        </button>

        <button
          onClick={() => addNode('diamond')}
          style={{ padding: '8px', borderRadius: '50%', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          title="Add Diamond Node"
        >
          <GoDiamond size={20} />
        </button>

        <button
          onClick={addEdgeDynamically}
          style={{ padding: '8px', borderRadius: '50%', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          title="Add Edge"
        >
          <FaLink size={20} />
        </button>

        <button
          onClick={removeSelectedElements}
          style={{ padding: '8px', borderRadius: '50%', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          title="Remove Selected"
        >
          <FaTrash size={20} />
        </button>

        <button
          onClick={saveToAPI}
          style={{ padding: '8px', borderRadius: '50%', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          title="Save Flowchart"
        >
          <FaSave size={20} />
        </button>
      </div>
    </div>
  );
};

export default BPMNFlowChart;
