import React, { useState, useEffect } from 'react';
import moment from "moment";
import { CgPlayListAdd } from "react-icons/cg";
import { FiMoreVertical, FiEdit, FiTrash, FiUser } from 'react-icons/fi';
import AddTaskModal from './AddTaskModal';
import MobileKanbanBoard from './MobileKanbanBoard';
import EditTaskModel from './EditTaskModel';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTaskStore } from '../Store/TaskStore/TaskStore';
import AssignUserModal from './AssignUserModal';
import { useAccountStore } from '../Store/AccountStore/AccountStore';
import { useInviteStore } from '../Store/InviteStore/InviteMemberStore';
import { MdOutlineNearMeDisabled } from 'react-icons/md';
import { useMemberStore } from '../Store/MemberStore/MemberStore';

const Card = ({ index, fetchTasks, board, members }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { deleteTask } = useTaskStore();
  const { userName } = useAccountStore();
  const [name, setName] = useState('')
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const findName = (id) => {
    let data = members.find((f) => f.id === id)
    if (data) {
      return data.name
    }
    return '-'
  }
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const openAssignModal = () => {
    setIsAssignModalOpen(true);
    setShowDropdown(false); // Close the dropdown when the modal is opened
  };

  const closeAssignModal = () => {
    setIsAssignModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
    setShowDropdown(false);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleDelete = async (taskId) => {
    try {
      await deleteTask(taskId);
      fetchTasks(); // Refresh the tasks after deletion
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  useEffect(() => {
    const fetchCreatorName = async () => {
      try {
        const namee = await userName(); // Fetch name based on user ID

        setName(namee.name);
        console.log(namee.name);

      } catch (error) {
        console.error('Error fetching creator name:', error);
      }
    };
    fetchCreatorName();
  }, [userName]);
  // console.log(item._id,board._id);
  return (
    <>
      <Draggable draggableId={board._id} index={index}>
        {(provided) => (
          <div
            className="card bg-white shadow-md rounded-lg p-4 mb-4"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="card-header flex justify-between items-center">
              <div>
                <h3 className="card-title font-semibold text-lg">{board.name}</h3>
              </div>
              <div className="relative">
                <FiMoreVertical
                  size={20}
                  className="dropdown-icon cursor-pointer"
                  onClick={toggleDropdown}
                />
                {showDropdown && (
                  <div className="card-dropdown absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-lg">
                    <button
                      className="card-dropdown-button flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                      onClick={openEditModal}
                    >
                      <FiEdit className="mr-2" /> Edit
                    </button>
                    <button
                      className="card-dropdown-button flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                      onClick={() => handleDelete(board._id)}
                    >
                      <FiTrash className="mr-2" /> Delete
                    </button>
                    <button
                      className="card-dropdown-button flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                      onClick={openAssignModal}
                    >
                      <FiUser className="mr-2" /> Assign
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="card-body">
              <span className="comments font-semibold">{board.subject}</span> <br />
              <span className="comments">{board.description}</span> <br />
              <span className="text-gray-600 text-sm">Created By: {findName(board.user)}</span>
            </div>

            <div className="card-footer text-right mt-[4rem]">
              <span className="date text-xs text-gray-500">
                {moment(board.createdAt).format('LLL')}
              </span>
            </div>
          </div>
        )}
      </Draggable>

      {isEditModalOpen && (
        <EditTaskModel
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          taskToEdit={board}
          onUpdateTask={fetchTasks}
        />
      )}

      {isAssignModalOpen && (
        <AssignUserModal
          visible={isAssignModalOpen}
          onClose={closeAssignModal}
          taskId={board._id}
        />
      )}
    </>
  );
};

const KanbanBoard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tasks2, setTasks] = useState([]);
  const { getTasks, dragTask, tasks } = useTaskStore();
  const { getInvite, invite } = useInviteStore()
  const handleOpenModal = () => {
    console.log("Add Task button clicked");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  console.log(invite);

  const handleAddTask = (newTask) => {
    console.log('New Task:', newTask);
  };

  const fetchTasks = async () => {
    try {
      await getTasks(); // Await the result of getTasks
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  useEffect(() => {
    fetchTasks(); // Load tasks on component mount
  }, []);

  const fetchInvites = async () => {
    try {
      await getInvite()
    }
    catch (err) {
      console.error('error fetching invites:', err);
    }
  };
  useEffect(() => {
    fetchInvites();
  }, [])


  const choices = {
    todo: 'To-Do',
    onProgress: 'On-Progress',
    done: 'Done',
    hold: 'Hold',
  };

  const columns = [
    {
      id: 'todo',
      title: 'To-Do List',
      count: tasks.filter(task => task.status === choices.todo).length + invite.filter(task => task.status === choices.todo).length,
      color: 'bg-purple-500',
      desc: "Your to do tasks appear here.",
    },
    {
      id: 'onProgress',
      title: 'In Progress',
      count: tasks.filter(task => task.status === choices.onProgress).length + invite.filter(task => task.status === choices.onProgress).length,
      color: 'bg-yellow-500',
      desc: "Tasks in progress.",
    },
    {
      id: 'done',
      title: 'Done',
      count: tasks.filter(task => task.status === choices.done).length + invite.filter(task => task.status === choices.done).length,
      color: 'bg-green-500',
      desc: "Tasks completed",
    },
    {
      id: 'hold',
      title: 'Hold',
      count: tasks.filter(task => task.status === choices.hold).length + invite.filter(task => task.status === choices.hold).length,
      color: 'bg-blue-500',
      desc: "Tasks hold",
    },
  ];

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    localStorage.setItem('draggableId', draggableId);

    const start = columns.find(col => col.id === source.droppableId);
    const finish = columns.find(col => col.id === destination.droppableId);

    if (start === finish) {
      const newTaskList = Array.from(tasks.filter(task => task.status === choices[source.droppableId]));
      const [movedTask] = newTaskList.splice(source.index, 1);
      newTaskList.splice(destination.index, 0, movedTask);

      const updatedTasks = tasks.map(task => {
        if (task._id === draggableId) {
          task.index = destination.index;
        }
        return task;
      });

      setTasks(updatedTasks);
    } else {
      const startTasks = Array.from(tasks.filter(task => task.status === choices[start.id]));
      const [movedTask] = startTasks.splice(source.index, 1);

      const finishTasks = Array.from(tasks.filter(task => task.status === choices[finish.id]));
      finishTasks.splice(destination.index, 0, movedTask);

      const updatedTasks = tasks.map(task => {
        if (task._id === draggableId) {
          task.status = choices[destination.droppableId];
          task.index = destination.index;
        }
        return task;
      });

      setTasks(updatedTasks);
    }

    const payload = {
      status: choices[destination.droppableId],
      index: destination.index
    };


    try {
      await dragTask(payload);
      console.log('Task status updated successfully');
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };

  const { subUser, members, setSubUser } = useAccountStore()

  useEffect(
    () => {
      if (members.length === 0) {
        subUser()
          .then(res => {
            setSubUser(res)
          })
      }
    }
    , [])

  return (
    <>
      <div className="kanban-board Reshidden mt-2">
        <div className="kanban-board-header">
          <div className="kanban-board-add-button Reshidden">
            <CgPlayListAdd size={25} />
            <button onClick={handleOpenModal}>Add Task</button>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <div className="kanban-board-grid">
            {columns.map((column) => (
              <div key={column.id}>
                <div className={`kanban-board-column-header ${column.color}`}>
                  <div className="flex justify-between">
                    <div>
                      <h2 className="font-semibold text-lg">{column.title} ({column.count})</h2>
                      <p className="text-sm">{column.desc}</p>
                    </div>
                  </div>
                </div>
                <Droppable droppableId={column.id}>
                  {(provided) => (
                    <div
                      className="kanban-board-column-body"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {tasks
                        .filter(task => task.status === choices[column.id])
                        .sort((a, b) => a.index - b.index)  // Sort by index to maintain order
                        .map((item, itemIndex) => (
                          <Card members={members} key={item._id} board={item} fetchTasks={fetchTasks} index={itemIndex} />
                        ))}
                      {invite
                        .filter(task => task.status === choices[column.id])
                        .sort((a, b) => a.index - b.index) // Sort by index to maintain order
                        .map((board, boardIndex) => {
                          // Check if Item and Item._id exist before rendering the Card
                          if (!board || !board._id) {
                            console.error('Item or Item._id is missing:', board);
                            return null; // Skip rendering for undefined or missing _id
                          }

                          return (
                            <Card
                              key={board._id}
                              board={board}
                              members={members}
                              index={boardIndex}
                            />
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>

        <AddTaskModal isOpen={isModalOpen} onClose={handleCloseModal} onAddTask={handleAddTask} fetchTask={fetchTasks} />
      </div>
      <MobileKanbanBoard fetchTasks={fetchTasks} isOpen={isModalOpen} openAddModal={handleOpenModal} onAddTask={handleAddTask} columns={columns} tasks={tasks} />
    </>
  );
};

export default KanbanBoard;

