// import React, { useState } from 'react';
// import { Handle } from 'react-flow-renderer';
// import './EPC.css';

// const FunctionNode = ({ data, id, selected, onNodeChange }) => {
//   const [label, setLabel] = useState(data.label);

//   const handleChange = (event) => {
//     const newLabel = event.target.value;
//     setLabel(newLabel);
//     onNodeChange(id, newLabel);
//   };

//   return (
//     <div className={`event-flow ${selected ? 'editing' : ''}`}>
//       <input
//         type="text"
//         value={label}
//         onChange={handleChange}
//         placeholder="Enter function text"
//         className="node-input"
//       />
//       <Handle type="target" position="top" />
//       <Handle type="source" position="bottom" />
//     </div>
//   );
// };

// export default FunctionNode;

import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './EPC.css';

const FunctionNode = ({ data, id, selected, onNodeChange, isConnectable }) => {
  const [label, setLabel] = useState(data.label);
  
  const { shape } = data;

  const getShapeStyle = () => {
    switch (shape) {
      case 'oval':
        return { borderRadius: '50%', width: '100px', height: '60px' };
      case 'diamond':
        return { transform: 'rotate(45deg)', width: '80px', height: '80px' };
      case 'rectangle':
      default:
        return { borderRadius: '5px', width: '120px', height: '60px' };
    }
  };

  const handleStyles = (position) => {
    switch (shape) {
      case 'diamond':
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
          transform: 'rotate(-45deg)',
        };
      default:
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
        };
    }
  };

  const handleChange = (event) => {
    const newLabel = event.target.value;
    setLabel(newLabel);
    onNodeChange(id, newLabel);
  };

  return (
    <div
      style={{
        position: 'relative',
        padding: '0',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        ...getShapeStyle(),
      }}
    >
      <input
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          textAlign: 'center',
          backgroundColor: 'transparent',
          padding: 0,
          margin: 0,
          transform: shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        }}
        value={label}
        onChange={handleChange}
      />
      <Handle type="target" position="top" style={handleStyles('top')} isConnectable={isConnectable} />
      <Handle type="source" position="bottom" style={handleStyles('bottom')} isConnectable={isConnectable} />
    </div>
  );
};

export default FunctionNode;
