import { create } from 'zustand';
import axios from "axios";

export const useBoardStore = create((set, get) => ({
    currentBoard: null,

    createBoard: async (payload) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios.post(
                " http://20.197.8.121:6001/create",
                payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            );
            console.log('Board Created successfully', response);
            return response.data;
        } catch (err) {
            console.error('failed to create board:', err);
            throw err;
        }
    },
    
    getBoard: async () => {
        const projectId = localStorage.getItem('ProjectId');
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`http://20.197.8.121:6001/getbyuserid/${userId}/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Boaard fetched successfully:', response);
            return response.data;
        } catch (err) {
            console.error('Failed to fetch boards:', err);
            throw err;
        }
    },
    getBoardId: async () => {
        const { currentBoard } = get();
        if (!currentBoard || !currentBoard._id) {
            throw new Error("No current board selected");
        }
    
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`http://20.197.8.121:6001/get/${currentBoard._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (err) {
            console.error('Failed to fetch board:', err);
            throw err;
        }
    },
    updateBoard: async (payload) => {
        const { currentBoard } = get();
        const token = localStorage.getItem('token');
    
        try {
            const response = await axios.put(
                `http://20.197.8.121:6001/updateShapes/${currentBoard._id}`,
                payload, // send payload directly, not inside an object like { body: payload }
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to update board:', err);
            throw err;
        }
    },
    updateBoardname:async(payload)=>{
        const { currentBoard } = get();
        const token = localStorage.getItem('token');
    
        try {
            const response = await axios.put(
                `http://20.197.8.121:6001/updatename/${currentBoard._id}`,
                payload, // send payload directly, not inside an object like { body: payload }
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to update board:', err);
            throw err;
        }
    },
    addshapes:async(payload)=>{
        const { currentBoard } = get();
        const token = localStorage.getItem('token');
    
        try {
            const response = await axios.post(
                `http://20.197.8.121:6001/shapes/${currentBoard._id}`,
                payload, // send payload directly, not inside an object like { body: payload }
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to update board:', err);
            throw err;
        }  
    },
    deleteBoard: async (boardId) => {
        const token = localStorage.getItem('token');
      
        try {
            const response = await axios.delete(
                `http://20.197.8.121:6001/delete/${boardId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to delete board:', err);
            throw err;
        }
    },
    updateProject:async(payload)=>{
        const{currentBoard}=get();
        const token=localStorage.getItem('token');
        try{
            const response = await axios.put(`http://20.197.8.121:6001/updateprojectId/${currentBoard._id}`,payload,{
                headers:{
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',  
                },
            });
            return response.data;
        }catch(err){
            console.error('failed to update projectid',err);
            throw err;
        }
    },

    setCurrentBoard: (board) => set({ currentBoard: board })
}))
