import React, { useState, useEffect } from "react";
import { IoSearch } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import CreateTeamModal from "./CreateTeamModal";
import MobileResponsiveTable from "./MobileResponsiveTable";
import moment from "moment";
import EditTeamModal from "./EditTeamModel";
import { useTeamStore } from "../Store/TeamStore/TeamStore";

const Team = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [currentTeam, setCurrentTeam] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  
  const { getTeam, deleteTeam, searchTeam, teams } = useTeamStore(); 

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      await getTeam();
      setErrorMessage(""); 
    } catch (error) {
      console.error("Error fetching teams", error);
      setErrorMessage("Failed to fetch teams."); 
    }
  };

  

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear the previous timeout if it exists
    }

    const newTimeout = setTimeout(async () => {
      if (value.trim()) {
        try {
          await searchTeam(value); 
          setErrorMessage(""); 
        } catch (error) {
          console.error("Error searching teams", error);
          setErrorMessage("Failed to search teams."); 
        }
      } else {
        fetchTeams(); 
      }
    }, 500); // 500ms delay

    setDebounceTimeout(newTimeout); // Set the new timeout
  };
  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    fetchTeams();
  };

  const openEditModal = (teams) => {
    setCurrentTeam(teams);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentTeam(null); 
  };

  const handleSaveEdit = () => {
    fetchTeams(); 
    closeEditModal();
  };

  const handleDeleteUser = async (teamId) => {
    try {
      await deleteTeam(teamId); 
      await fetchTeams();
    } catch (error) {
      console.error("Error deleting user", error);
      setErrorMessage("Failed to delete the teams. Please try again.");
    }
  };
const name=localStorage.getItem('name');
  return (
    <>
      <div className="min-h-screen bg-gray-100 p-4 Reshidden">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <header className="header-container">
            <h1 className="header-title Reshidden">Teams</h1>
            <div
              onClick={openCreateModal}
              className="Reshidden create-project-button cursor-pointer flex items-center gap-2"
            >
              <IoMdAdd size={20} />
              <button className="font-semibold">Create team</button>
            </div>
          </header>

          <div className="bg-blue-600 text-white p-3 rounded mb-4">
            <span>{teams.length} member(s) in the team.</span>
          </div>

          <div className="search-container flex items-center border border-gray-300 rounded-md p-2 mb-4">
            <IoSearch size={25} />
            <input
              type="text"
              placeholder="Search Team"
              className="search-input ml-2 outline-none"
              value={searchTerm}
              onChange={handleSearchChange} 
            />
          </div>

          {errorMessage && (
            <div className="bg-red-100 text-red-600 p-3 rounded mb-4">
              {errorMessage}
            </div>
          )}

          {teams.length > 0 ? (
            <div className="overflow-x-auto">
              <table
                className="table w-full bg-white border border-gray-200 rounded-md"
                style={{ verticalAlign: "center" }}
              >
                <thead>
                  <tr className="table-header">
                    <th className="table-header-cell">Name</th>
                    <th className="table-header-cell">Created Date</th>
                    <th className="table-header-cell">Created By</th>
                    <th className="table-header-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {teams.map((user) => (
                    <tr key={user._id} className="table-row">
                      <td
                        className="space-y-2 flex items-center"
                        style={{
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <div className="avatar">
                          <span className="avatar-text">
                            {user.teamname.charAt(0)}
                          </span>
                        </div>
                        <div>
                          <p className="font-semibold">{user.teamname}</p>
                        </div>
                      </td>
                      <td className="table-cell">
                        {moment(user.createdAt).format("LLL")}
                      </td>
                      <td className="table-cell">{name}</td>
                      <td className="table-cell flex space-x-2">
                        <button
                          className="actions-button p-2 hover:bg-gray-200 rounded-md"
                          onClick={() => openEditModal(user)}
                        >
                          <FiEdit size={20} />
                        </button>
                        <button
                          className="delete-button p-2 hover:bg-gray-200 rounded-md"
                          onClick={() => handleDeleteUser(user._id)} // Pass the team ID
                        >
                          <FiTrash2 size={20} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            !errorMessage && <p>No teams available.</p> 
          )}
        </div>
        <CreateTeamModal
          isOpen={isCreateModalOpen}
          onClose={closeCreateModal}
        />
        {currentTeam && (
          <EditTeamModal
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
            team={currentTeam}
            onSave={handleSaveEdit}
          />
        )}
      </div>
      <MobileResponsiveTable
        fetchUsers={fetchTeams}
        handleDeleteUser={handleDeleteUser}
        users={teams}
        onSave={handleSaveEdit}
      />
    </>
  );
};

export default Team;
