import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import EditTeamModal from "./EditTeamModel";
import moment from "moment";

const MobileResponsiveTable = (props) => {

const {fetchUsers,handleDeleteUser,users}=props
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const [currentTeam, setCurrentTeam] = useState(null);

const openEditModal = (team) => {
  setCurrentTeam(team);
  setIsEditModalOpen(true);
};

const closeEditModal = () => {
  setIsEditModalOpen(false);
  setCurrentTeam(null); // Clear the current team
};

const handleSaveEdit = () => {
  fetchUsers(); // Refresh users after edit
  closeEditModal();
};
const name=localStorage.getItem('name');
console.log(name);


  return (
    <div className="min-h-screen bg-gray-100 md:hidden">
      <div className=" rounded-lg  p-2">
        <div className="bg-blue-600 text-white p-3 rounded mb-4">
          <span>{users.length} member(s) in the team.</span>
        </div>

        <div className="search-container flex items-center border border-gray-300 rounded-md p-2">
          <IoSearch size={25} />
          <input
            type="text"
            placeholder="Search Team"
            className="search-input ml-2 outline-none"
          />
        </div>

        <div style={{marginBottom:'10px'}}>
          {users.map((user,count) => (
            <div key={count} className="bg-white p-4 rounded-lg shadow-md">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center text-xl font-semibold text-gray-600 mr-4">
                  {user.teamname.charAt(0)}
                </div>
                <div>
                  <p className="font-semibold">{user.teamname}</p>
                </div>
              </div>
              <div className="text-gray-600 mb-4">
                <p className="text-sm">Created Date:{moment(user.createdAt).format('LLL')}</p>
                <p className="text-sm">Created By: {name}</p>
              </div>
              <div className="flex justify-end space-x-2">
                <button className="p-2 text-blue-500 hover:bg-gray-200 rounded-md">
                  <FiEdit size={20}  onClick={()=>openEditModal(user)}/>
                </button>
                <button className="p-2 text-red-500 hover:bg-gray-200 rounded-md">
                  <FiTrash2 size={20} onClick={()=>handleDeleteUser(user._id)}/>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {currentTeam && (
          <EditTeamModal
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
            team={currentTeam}
            onSave={handleSaveEdit}
          />
        )}
    </div>
  );
};

export default MobileResponsiveTable;
