import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Spin, message } from 'antd'; // Import Ant Design components
import axios from 'axios';
import { useAccountStore } from '../Store/AccountStore/AccountStore';
import { useTaskStore } from '../Store/TaskStore/TaskStore';
const AssignUserModal = ({ visible, onClose, taskId }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]); // Updated to hold multiple selected members
  const { subUser } = useAccountStore();
  const { assignTask } = useTaskStore();

  useEffect(() => {
    if (visible) {
      fetchMembers();
    }
  }, [visible]);

  const fetchMembers = async () => {
    setLoading(true);
    try {
      const response = await subUser(); // Replace with your API endpoint for fetching members
      setMembers(response);
    } catch (error) {
      message.error('Failed to load members');
    } finally {
      setLoading(false);
    }
  };

  const handleAssign = async () => {
    if (selectedMembers.length === 0) {
      message.warning('Please select at least one member');
      return;
    }
    console.log(selectedMembers);

    try {
      await assignTask({ taskId, assignedTo: selectedMembers })
      console.log(taskId, selectedMembers)
      message.success('Members assigned successfully');
      onClose(); 
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to assign members';
      message.error(errorMessage);
    }
  };

  return (
    <Modal
      title="Assign Users"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="assign" type="primary" onClick={handleAssign}>
          Assign
        </Button>,
      ]}
    >
      {loading ? (
        <Spin /> // Show a loading spinner while fetching members
      ) : (
        <Select
          mode="multiple" // Enable multiple selection
          placeholder="Select members"
          style={{ width: '100%' }}
          onChange={(value) => setSelectedMembers(value)}
          value={selectedMembers}
        >
          {members.map((member) => (
            <Select.Option key={member.id} value={member.id}>
              {member.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </Modal>
  );
};

export default AssignUserModal;
