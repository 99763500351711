import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './EPC.css'; // Import the CSS file for styles

const EventAndFunctionNode = ({ data, id, selected, onNodeChange, isConnectable }) => {
  const [label, setLabel] = useState(data.label);
  const { shape, type } = data; // Destructure shape and type from data

  // Handle label change
  const handleChange = (event) => {
    const newLabel = event.target.value;
    setLabel(newLabel);
    onNodeChange(id, newLabel);
  };

  // Get shape styles based on shape type
  const getShapeStyle = () => {
    switch (shape) {
      case 'oval':
        return { borderRadius: '50%', width: '100px', height: '60px' };
      case 'diamond':
        return { transform: 'rotate(45deg)', width: '80px', height: '80px' };
      case 'rectangle':
      default:
        return { borderRadius: '5px', width: '120px', height: '60px' };
    }
  };

  // Position handles based on shape type
  const handleStyles = (position) => {
    switch (shape) {
      case 'diamond':
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
          transform: 'rotate(-45deg)',
        };
      default:
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
        };
    }
  };

  // Render function for EventNode
  const renderEventNode = () => (
    <div className={`flow ${selected ? 'editing' : ''}`}>
      <input
        type="text"
        value={label}
        onChange={handleChange}
        placeholder="Enter event text"
        className="node-input"
      />
      <Handle type="target" position="top" />
      <Handle type="source" position="bottom" />
    </div>
  );

  // Render function for FunctionNode
  const renderFunctionNode = () => (
    <div
      style={{
        position: 'relative',
        padding: '0',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        ...getShapeStyle(),
      }}
    >
      <input
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          textAlign: 'center',
          backgroundColor: 'transparent',
          padding: 0,
          margin: 0,
          transform: shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        }}
        value={label}
        onChange={handleChange}
      />
      <Handle type="target" position="top" style={handleStyles('top')} isConnectable={isConnectable} />
      <Handle type="source" position="bottom" style={handleStyles('bottom')} isConnectable={isConnectable} />
    </div>
  );

  // Determine which node type to render
  return type === 'event' ? renderEventNode() : renderFunctionNode();
};

export default EventAndFunctionNode;
