import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './System.css';

const SystemNode = ({ id, data, selected, onNodeChange, isConnectable }) => {
  const [label, setLabel] = useState(data.label);

  const handleChange = (event) => {
    const newLabel = event.target.value;
    setLabel(newLabel);
    data.label = newLabel; // Update data.label with the new label
    onNodeChange(id, newLabel); // Notify parent component about label change
  };

  const getShape = () => {
    const shapeType = data.shape.toLowerCase();
    if (shapeType === 'oval') {
      return 'oval';
    }
    if (shapeType === 'diamond') {
      return 'diamond';
    }
    return 'rectangle';
  };

  const shape = getShape();

  const getShapeStyle = () => {
    switch (shape) {
      case 'oval':
        return { borderRadius: '50%', width: '100px', height: '60px' };
      case 'diamond':
        return { transform: 'rotate(45deg)', width: '80px', height: '80px' };
      case 'rectangle':
      default:
        return { borderRadius: '5px', width: '120px', height: '60px' };
    }
  };

  const handleStyles = (position) => {
    if (shape === 'diamond') {
      return {
        top: position === 'top' ? '10%' : position === 'bottom' ? '10%' : 'auto',
        left: position === 'left' ? '10%' : position === 'right' ? '10%' : 'auto',
        transform: position === 'top' || position === 'bottom' ? 'rotate(-45deg)' : 'rotate(45deg)',
      };
    } else {
      return {
        top: position === 'top' ? '10%' : 'auto',
        bottom: position === 'bottom' ? '10%' : 'auto',
        left: position === 'left' ? '10%' : 'auto',
        right: position === 'right' ? '10%' : 'auto',
      };
    }
  };

  return (
    <div
      className={`system-node ${shape} ${selected ? 'editing' : ''}`}
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        overflow: 'hidden',
        ...getShapeStyle(),
      }}
    >
      <input
        type="text"
        value={label}
        onChange={handleChange}
        className="node-input"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          textAlign: 'center',
          backgroundColor: 'transparent',
          padding: 0,
          margin: 0,
          transform: shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        }}
      />
      <Handle
        type="target"
        position="top"
        style={handleStyles('top')}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="bottom"
        style={handleStyles('bottom')}
        isConnectable={isConnectable}
      />
      {shape === 'diamond' && (
        <>
          <Handle
            type="source"
            position="left"
            style={handleStyles('left')}
            isConnectable={isConnectable}
          />
          <Handle
            type="target"
            position="right"
            style={handleStyles('right')}
            isConnectable={isConnectable}
          />
        </>
      )}
    </div>
  );
};

export default SystemNode;



