import React, { useState, useEffect } from "react";
import { MdLogout } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import ChangePasswordPopUp from "./ChangePasswordPopUp";
import { IoKeyOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../Store/AccountStore/AccountStore";

const ProfilePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const { userProfile } = useAccountStore();
  const navigate = useNavigate();

  // Fetch user profile data when the component mounts
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await userProfile();
       
        localStorage.setItem('name',profile.name);
        setUserData({
          name: profile.name || "", // Assuming `profile` has `name`, `email`, and `contact` fields
          email: profile.email || "",
          mobile: profile.contact || "", // Ensure the correct mapping
        });
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    };

    fetchUserProfile();
  }, [userProfile]);

  const togglePopup = () => {
    setIsModalOpen(!isModalOpen);
    console.log("Modal open state:", !isModalOpen);
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("ProjectId");
      localStorage.removeItem("drawingId");
      localStorage.removeItem("draggableId");
      localStorage.removeItem("flowchartId");
      localStorage.removeItem("chartId");
      sessionStorage.clear();
      navigate("/");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="profile-container">
        <div className="profile-card">
          <div className="flex justify-end gap-2 mb-4">
            <div
              onClick={handleLogout}
              className="flex items-center border p-2 border-gray-400 cursor-pointer"
            >
              <MdLogout className="" />
            </div>
            <button
              onClick={togglePopup}
              className="flex items-center border justify-between border-gray-400 p-2"
            >
              <IoKeyOutline size={15} />
            </button>
          </div>
          <h2 className="profile-header Reshidden">User Profile</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="profile-section">
              <label className="profile-label" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={userData.name}
                onChange={handleInputChange}
                className="profile-input"
                placeholder="Enter your name"
              />
            </div>
            <div className="profile-section">
              <label className="profile-label" htmlFor="email">
                Email ID
              </label>
              <input
                type="email"
                name="email"
                value={userData.email}
                className="profile-input"
                placeholder="Enter your email"
                readOnly // This makes the email field non-editable
              />
            </div>
            <div className="profile-section">
              <label className="profile-label" htmlFor="mobile">
                Mobile Number
              </label>
              <input
                type="tel"
                name="mobile"
                value={userData.mobile} // Corrected the value to userData.mobile
                onChange={handleInputChange}
                className="profile-input"
                placeholder="Enter your mobile number"
              />
            </div>
          </div>
          <div className="flex justify-end mb-6">
            <button className="btn-primary">Save</button>
          </div>
        </div>
      </div>
      {isModalOpen && <ChangePasswordPopUp toggleModal={togglePopup} />}
    </>
  );
};

export default ProfilePage;
