import React, { useRef, useState, useEffect } from "react";
import * as fabric from 'fabric';
import { useNavigate, useParams } from 'react-router-dom';
import { ImPencil2 } from "react-icons/im";
import { FaHighlighter } from "react-icons/fa";
import { LuEraser } from "react-icons/lu";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaRegCircle } from "react-icons/fa";
import { MdOutlineRectangle } from "react-icons/md";
import { IoTriangleOutline } from "react-icons/io5";
import { PiLineVertical } from "react-icons/pi";
import { BiPolygon } from "react-icons/bi";
import { PiTextbox } from "react-icons/pi";
import Flowchart from './flowchart/Flowchart'
import './Canvas.css';
import { IoMdAdd } from 'react-icons/io';
import CreateMembersModal from './CreateMemberModal';
import { useBoardStore } from "../Store/BoardStore/BoardStore";
import { useProjectStore } from "../Store/ProjectStore/ProjectStore";

export default function Whiteboard() {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [color, setColor] = useState("#3B3B3B");
  const [size, setSize] = useState(3);
  const [cursor, setCursor] = useState("default");
  const [mode, setMode] = useState('');
  const [activeButton, setActiveButton] = useState('');
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const [boards, setBoards] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [updateMe, setUpdateMe] = useState(false);
  const [canvasHistory, setCanvasHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const { getBoardId, setCurrentBoard, updateBoard, updateBoardname, addshapes, updateProject } = useBoardStore();

  // const [projects, setProjects] = useState([]); 
  const [selected, setSelected] = useState("")
  const { getProject, project } = useProjectStore();

  const fetchBoard = async () => {
    try {
      const response = await getBoardId();
      console.log('Board response:', response);

      const ID = response.projectId
      if (response) {
        setBoards(response);
        setName(response.name);
        setSelected(ID);
        if (response.shapes) {
          setCanvasHistory([response.shapes]);
          setHistoryIndex(0);
        }
      } else {
        console.error('Board response is null or undefined.');
      }
    } catch (error) {
      console.error('Error fetching board:', error);
    }
  };

  useEffect(() => {
    if (id) {
      setCurrentBoard({ _id: id });
      fetchBoard();
    }
  }, [id, setCurrentBoard]);

  //   useEffect(()=>{
  //      const fetchProjects=async()=>{
  //     try{
  //     const project = await getProject();
  //     console.log(project)
  //     setProjects(project);
  //     }catch(err){
  //       console.error("Failed to fetch projects:", err);
  //     }
  //   };
  // fetchProjects();
  // },[getProject]) 
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        await getProject(); // Assuming getProject is a function that fetches projects
      } catch (err) {
        console.error('Failed to fetch projects:', err);
      }
    };

    fetchProjects();
  }, []);

  const handleSelectChange = (event) => {
    if (event && event.target) {
      const selectedProjectId = event.target.value;

      const payload = { projectId: selectedProjectId };

      setSelected(selectedProjectId);

      // Log the payload to verify its structure before making the request
      console.log("Payload for updateProject:", payload);

      // Call updateProject with the correctly structured payload
      updateProject(payload)
        .then(response => {
          console.log("Updated Project:", response);
        })
        .catch(error => {
          console.error("Failed to update project:", error);
        });
    } else {
      console.error("Event target is undefined:", event);
    }
  };

  const updateShapes = async () => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;

    if (!canvas) return;

    const shapes = canvas.toJSON().objects;

    if (!Array.isArray(shapes)) {
      console.error("Shapes is not an array:", shapes);
      return;
    }

    const newHistory = [...canvasHistory.slice(0, historyIndex + 1), shapes];
    setCanvasHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);

    try {
      await updateBoard({ shapes: shapes });
    } catch (error) {
      console.error("Error updating board:", error);
    }
  };

  const updateDrawingName = async () => {
    if (!name || typeof name !== 'string') {
      console.error("Invalid name");
      return;
    }
    try {
      await updateBoardname({ name });
    } catch (error) {
      console.error("Error updating drawing name:", error);
    }
  };

  const handleBlur = () => {
    updateDrawingName(name);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    } else {
      const canvas = canvasRef.current;
      if (boards.shapes) {
        redraw(boards.shapes);
      }
      const ctx = canvas.getContext("2d");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
  }, [navigate, boards]);


  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current);
    canvas.setWidth(window.innerWidth);
    canvas.setHeight(window.innerHeight);
    canvasRef.current.__fabricCanvas = canvas;
    canvas.on('object:modified', updateShapes);
    canvas.on('path:created', updateShapes);
    return () => {
      if (canvasRef.current && canvasRef.current.__fabricCanvas) {
        canvasRef.current.__fabricCanvas.dispose();
      }
    };
  }, [canvasRef]);

  // useEffect(() => {
  //   if (isFlowchartSelected) {
  //     renderFlowchartOnCanvas();
  //   }
  // }, [isFlowchartSelected]);

  // const toggleFlowchartSelection = () => {
  //   setIsFlowchartSelected(!isFlowchartSelected);
  // };

  const saveCanvasState = async () => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas) {
      const canvasState = canvas.toJSON();
      const shapesArray = canvasState.objects;
      try {
        await addshapes({ shapes: shapesArray });
      } catch (error) {
        console.error('Error saving canvas state:', error);
      }
    }
  };


  const addShape = (shapeType) => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas) {
      let shape;
      const options = {
        left: Math.random() * 700,
        top: Math.random() * 500,
        fill: 'transparent',
        stroke: 'black',
        strokeWidth: 2,
      };

      switch (shapeType) {
        case 'circle':
          shape = new fabric.Circle({ ...options, radius: 50 });
          break;
        case 'rectangle':
          shape = new fabric.Rect({ ...options, width: 100, height: 100 });
          break;
        case 'triangle':
          shape = new fabric.Triangle({ ...options, width: 100, height: 100 });
          break;
        case 'line':
          shape = new fabric.Line([50, 50, 200, 200], { stroke: 'black', strokeWidth: 2 });
          break;
        case 'ellipse':
          shape = new fabric.Ellipse({ ...options, rx: 70, ry: 50 });
          break;
        case 'path':
          shape = new fabric.Path('M 0 0 L 100 100 L 200 0 Z', { stroke: 'black', fill: 'transparent', strokeWidth: 2 });
          break;
        case 'polygon':
          shape = new fabric.Polygon([{ x: 0, y: 0 }, { x: 100, y: 100 }, { x: 100, y: 0 }], { stroke: 'black', fill: 'transparent', strokeWidth: 2 });
          break;
        case 'group':
          const circle = new fabric.Circle({ ...options, radius: 30 });
          const rect = new fabric.Rect({ ...options, left: 70, top: 70, width: 60, height: 60 });
          shape = new fabric.Group([circle, rect], { left: 150, top: 150 });
          break;
        case 'textbox':
          shape = new fabric.Textbox('Type here', {
            left: Math.random() * 700,
            top: Math.random() * 500,
            fontSize: 20,
            width: 150,
            fill: 'black',
            borderColor: 'black',
            editable: true,
          });
          break;
        default:
          return;
      }
      canvas.add(shape);
      canvas.renderAll();
      // updateShapes();
      setMode('shapes');
      saveCanvasState();
    }
  };

  const setupDrawingMode = () => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas) {
      if (mode === 'erase' || mode === 'pencil' || mode === 'highlighter') {
        canvas.isDrawingMode = true;
        if (!canvas.freeDrawingBrush) {
          canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        }
        canvas.freeDrawingBrush.color = color;
        canvas.freeDrawingBrush.width = size;
        canvas.freeDrawingBrush.opacity = mode === 'highlighter' ? 0.5 : 1;
      } else {
        canvas.isDrawingMode = false;
      }
    }
  };

  useEffect(() => {
    setupDrawingMode();
  }, [mode, color, size]);

  const clearCanvas = () => {
    setBoards([]);
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas) {
      canvas.clear();
      canvas.renderAll();
      updateShapes()
    }
  };

  const redraw = (savedShapes) => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas && savedShapes) {
      canvas.clear();
      canvas.loadFromJSON({ objects: savedShapes }, () => {
        canvas.renderAll();
        setUpdateMe(true)
      });
      if (canvasHistory.length === 0) {
        setCanvasHistory([savedShapes]);
        setHistoryIndex(0);
      }
    }
  };

  useEffect(() => {
    if (boards.shapes) {
      redraw(boards.shapes);
    }
  }, [boards]);

  const undo = async () => {
    console.log('m chal rh hu')
    if (historyIndex > 0) {
      const newIndex = historyIndex - 1;
      setHistoryIndex(newIndex);
      const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
      if (canvas) {
        canvas.loadFromJSON(canvasHistory[newIndex], () => {
          canvas.renderAll();
          console.log('Undo applied: ', canvasHistory[newIndex]);
          updateShapes();
        });
      }
    } else {
      console.log('No more actions to undo.');
    }
  };

  const redo = async () => {
    console.log('m bhi chal rh hu');

    if (historyIndex < canvasHistory.length - 1) {
      const newIndex = historyIndex + 1;
      setHistoryIndex(newIndex);
      const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
      if (canvas) {
        canvas.loadFromJSON(canvasHistory[newIndex], () => {
          canvas.renderAll();
          console.log('Redo applied: ', canvasHistory[newIndex]);
          updateShapes();
        });
      }
    } else {
      console.log('No more actions to redo.');
    }
  };

  const getPen = () => {
    setCursor("default");
    setSize(3);
    setColor("#3B3B3B");
    setMode('pencil');
    setActiveButton('pencil');

  };

  const eraseCanvas = () => {
    setCursor("grab");
    setSize(20);
    setColor("#F0F0F0");
    setMode('erase');
    setActiveButton('erase');

  };

  const activateHighlighter = () => {
    setCursor("crosshair");
    setSize(10);
    setColor("#FFFF00");
    setMode('highlighter');
    setActiveButton('highlighter');

  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleShapeClick = (shapeType) => {
    setActiveButton(shapeType);
    addShape(shapeType);
  };

  const handleActionClick = (actionType) => {
    setActiveButton(actionType);
    if (actionType === 'clear') {
      clearCanvas();
    } else if (actionType === 'undo') {
      undo();
    } else if (actionType === 'redo') {
      redo();
    }
  };
  return (
    < div className="flex justify-between flex-wrap items-center bg-gray-100 p-2 rounded-lg " >
    <div className=' flex'>
      <div
        style={{
          fontWeight: 'bold',
          color: '#000',
          fontSize: '1.25rem',
          marginRight: '1rem',
        }}
      >
        <span>Whiteboard</span>
      </div>

      {/* Input Field */}
      <div style={{ fontFamily: 'sans-serif', color: '#000' }}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={handleBlur}
          style={{
            backgroundColor: '#60A5FA',
            border: 'none',
            color: '#ffffff',
            fontSize: '1.25rem',
            outline: 'none',
            width: '80%',
            padding: '0.2rem',
            borderRadius: '4px',
          }}
        />
      </div>
      </div>
      <div style={{  alignItems: 'center', marginTop: '0rem' }} className=" flex gap-x-2">
        {/* Select dropdown on the left */}
        <div >
          <select
            id="project-select"
            value={selected}
            onChange={handleSelectChange}
            style={{
              padding: '0.5rem 1rem',
              borderRadius: '4px',
              width:'100%',
              fontSize: '1rem',
              height: '2.5rem', // Same height as button for consistency
            }}
          >
            <option value="" disabled>
              Select a project
            </option>
            {project.map((p) => (
              <option key={p._id} value={p._id}>
                {p.projectname}
              </option>
            ))}
          </select>
        </div>

        {/* Invite Members button on the right */}
        <div
          onClick={togglePopup}
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3b82f6',
            color: '#ffffff',
            padding: '0.5rem 1rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            gap: '0.5rem',
            height: '2.5rem', // Ensures the button is the same height as the select
          }}
        >
          <IoMdAdd size={20} />
          <button
            style={{
              fontWeight: '500',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color: '#ffffff',
              height: '100%', // Ensures the button fills the parent container
            }}
          >
            Invite Members
          </button>
        </div>
      </div>




      {isPopupOpen && <CreateMembersModal togglePopup={togglePopup} />}

      <div className="canvas">


        <div className="canvas-btn flex flex-wrap justify-between mt-4">
          <button
            onClick={getPen}
            className={`btn-width ${activeButton === 'pencil' ? 'active' : ''} flex items-center`}
          >
            <ImPencil2 />
            <span className="tooltip">Pencil</span>
          </button>
          <button
            onClick={activateHighlighter}
            className={`btn-width ${activeButton === 'highlighter' ? 'active' : ''} flex items-center`}
          >
            <FaHighlighter />
            <span className="tooltip">Highlighter</span>
          </button>
          <button
            onClick={eraseCanvas}
            className={`btn-width ${activeButton === 'erase' ? 'active' : ''} flex items-center`}
          >
            <LuEraser />
            <span className="tooltip">Eraser</span>
          </button>
          <div className="btn-width flex items-center">
            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
            <span className="tooltip">Color Picker</span>
          </div>
          <div className="btn-width flex items-center">
            <select
              className="btn-width"
              value={size}
              onChange={(e) => setSize(parseInt(e.target.value))}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            <span className="tooltip">Brush Size</span>
          </div>

          <button
            onClick={() => handleShapeClick('circle')}
            className={`btn-width flex items-center ${activeButton === 'circle' ? 'active' : ''}`}
          >
            <FaRegCircle />
            <span className="tooltip">Circle</span>
          </button>
          <button
            onClick={() => handleShapeClick('rectangle')}
            className={`btn-width flex items-center ${activeButton === 'rectangle' ? 'active' : ''}`}
          >
            <MdOutlineRectangle />
            <span className="tooltip">Rectangle</span>
          </button>
          <button
            onClick={() => handleShapeClick('triangle')}
            className={`btn-width flex items-center ${activeButton === 'triangle' ? 'active' : ''}`}
          >
            <IoTriangleOutline />
            <span className="tooltip">Triangle</span>
          </button>
          <button
            onClick={() => handleShapeClick('line')}
            className={`btn-width flex items-center ${activeButton === 'line' ? 'active' : ''}`}
          >
            <PiLineVertical />
            <span className="tooltip">Line</span>
          </button>
          <button
            onClick={() => handleShapeClick('polygon')}
            className={`btn-width flex items-center ${activeButton === 'polygon' ? 'active' : ''}`}
          >
            <BiPolygon />
            <span className="tooltip">Polygon</span>
          </button>
          <button
            onClick={() => handleShapeClick('path')}
            className={`btn-width flex items-center ${activeButton === 'path' ? 'active' : ''}`}
          >
            <IoTriangleOutline />
            <span className="tooltip">Path</span>
          </button>
          <button
            onClick={() => handleShapeClick('textbox')}
            className={`btn-width flex items-center ${activeButton === 'textbox' ? 'active' : ''}`}
          >
            <PiTextbox />
            <span className="tooltip">Text Box</span>
          </button>
          <button
            onClick={() => handleActionClick('clear')}
            className={`btn-width flex items-center ${activeButton === 'clear' ? 'active' : ''}`}
          >
            <RiDeleteBin5Fill />
            <span className="tooltip">Clear All</span>
          </button>
          {/* undo redo button */}
          {/* <button
        onClick={() => handleActionClick('undo')}
        className={`btn-width flex items-center ${activeButton === 'undo' ? 'active' : ''}`}
      >
        Undo
      </button>
      <button
        onClick={() => handleActionClick('redo')}
        className={`btn-width flex items-center ${activeButton === 'redo' ? 'active' : ''}`}
      >
        Redo
      </button> */}

        </div>
        {/* <button onClick={toggleFlowchartSelection} className="btn-width flex items-center">

        <span className="tooltip">Flowchart</span>
      </button>

      <div className="my-4">
        <div style={{ marginBottom: '20px' }}>
          <select onChange={handleSelect} value={selectedFlowchart}>
            <option value="">Select a Flowchart</option>
            <option value="process">Process</option>
            <option value="program">Program</option>
            <option value="swimlance">Swimlance</option>
            <option value="system">System</option>
            <option value="workflow">Workflow</option>
            <option value="epc">EPC</option>
            <option value="decision">Decision</option>
            <option value="dataflow">Dataflow</option>
            <option value="crossfunctional">Cross Functional</option>
            <option value="bpmn">BPMN</option>
          </select>
        </div>
        <div id="flowchart" style={{backgroundColor:'transparent',width:'100%',height:'100%'}}>
          <Flowchart handleSelect={handleSelect} selectedFlowchart={selectedFlowchart} />
        </div>
      </div> */}
        <canvas
          id="canvas"
          ref={canvasRef}
          style={{ width: "100%", height: "100%", cursor: cursor }}
        />
      </div>
    </div>
  );

}
