import {React,useState} from "react";
import { IoSearch } from "react-icons/io5";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import EditProjectModal from "./EditProjectModel";

const MobileProjectTable = (props) => {
  const { projects, fetchProjects,handleDeleteUser } = props;
  const [isEditProjectOpen, setIsEditProjectOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleOpenEditModal = (project) => {
    setSelectedProject(project);
    setIsEditProjectOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditProjectOpen(false);
    setSelectedProject(null);
  };

  const handleSaveEdit = () => {
    // Refresh project list after editing
    fetchProjects();
  };
  const name= localStorage.getItem('name')
  return (
    <div className=" bg-gray-100 md:hidden">
      <div className="max-w-full mx-auto rounded-lg p-2">
        <div className="bg-blue-600 text-white p-3 rounded mb-4">
          <span>{projects.length} project(s) below is in the list.</span>
        </div>

        <div className="search-container flex items-center border border-gray-300 rounded-md p-2 mb-4">
          <IoSearch size={25} />
          <input
            type="text"
            placeholder="Search Projects"
            className="search-input ml-2 outline-none flex-1"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4" style={{marginBottom:'30px'}} >
          {projects.map((project) => (
            <div key={project._id} className="bg-white p-4 rounded-lg shadow-md">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center text-xl font-semibold text-gray-600 mr-4">
                  {project.projectname.charAt(0)}
                </div>
                <div>
                  <p className="font-semibold">{project.projectname}</p>
                </div>
              </div>
              <div className="text-gray-600 mb-4">
                <p className="text-sm">
                  Created Date: {new Date(project.createdAt).toLocaleDateString()}
                </p>
                <p className="text-sm">Created By: {name}</p>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  className="p-2 text-blue-500 hover:bg-gray-200 rounded-md"
                  onClick={() => handleOpenEditModal(project)}
                >
                  <FiEdit size={20} />
                </button>
                <button
                  className="p-2 text-red-500 hover:bg-gray-200 rounded-md"
                  onClick={() => handleDeleteUser(project._id)} // Assuming you have this function passed or declared
                >
                  <FiTrash2 size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <EditProjectModal
          isOpen={isEditProjectOpen}
          onClose={handleCloseEditModal}
          project={selectedProject}
          onSave={handleSaveEdit}
        />
    </div>
  );
};

export default MobileProjectTable;
