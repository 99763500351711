import React, { useEffect, useState, useMemo } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  Controls,
} from 'react-flow-renderer';
import './Flowchart.css'
import { useFlowchartStore } from '../../Store/FlowchartStore/FlowchartStore';
import CustomNode from './process/CustomNode';
import ProgramNode from './program/ProgramNode';
import SwimlanceNode from './swimlance/SwimlanceNode';
import SystemNode from './system/SystemNode';
import WorkflowNodes from './workflow/CustomNode';
import EPCNode from './EPC/EventAndFunctionNode';
import Decision from './decision/DecisionNode';
import Dataflow from './dataflow/DataFlowNode';
import Crossfuntion from './crossfuntional/CrossFunctionalAndLaneNode';
import BPMN from './BPMN/MargedNode';

import { FaLink, FaTrash, FaSave } from 'react-icons/fa';
import { FiSquare } from 'react-icons/fi'; 
import { FaRegCircle } from "react-icons/fa";
import { GoDiamond } from "react-icons/go";

const nodeTypesMap = {
  process: CustomNode,
  program: ProgramNode,
  swimlance: SwimlanceNode,
  system: SystemNode,
  workflow: WorkflowNodes,
  EPC: EPCNode,
  decision: Decision,
  dataflow: Dataflow,
  crossfuntional: Crossfuntion,
  BPMN: BPMN,
};

const Flowchart = () => {
  const { getChartId, updateChart } = useFlowchartStore();
  const [elements, setElements] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [type, setType] = useState('');

  const fetchChart = async () => {
    try {
      const data = await getChartId();
      console.log('Fetched data:', data);
      localStorage.setItem('Id', data._id);

      if (data && data.flowcharts && data.flowcharts.length > 0) {
        setType(data['type']);
        const chart = data.flowcharts[0];
        setNodes(chart.nodes);
        setEdges(chart.edges);
        const nodes = chart.nodes.map((node) => ({
          id: node.id,
          type: node.type || 'default',
          data: { label: node.data.label, shape: node.data.shape },
          position: node.position,
        }));

        const edges = chart.edges.map((edge) => ({
          id: edge.id,
          source: edge.source,
          target: edge.target,
          animated: edge.animated || false,
        }));

        console.log('Mapped Elements:', [...nodes, ...edges]);
        setElements([...nodes, ...edges]);
      } else {
        console.warn('No flowchart data available');
      }
    } catch (error) {
      console.error('Error fetching flowcharts:', error);
      setElements([]);
    }
  };

  useEffect(() => {
    fetchChart();
  }, []);

  const saveToAPI = async () => {
    try {
      const projectId = localStorage.getItem('ProjectId');
      const flowchart = { nodes, edges };
      await updateChart({ flowcharts: flowchart, projectId });
      console.log('Flowchart updated successfully.');
      alert('Flowchart updated successfully');
    } catch (error) {
      console.error('Failed to save flowchart to API', error);
    }
  };

  const addNode = (shape) => {
    const newNode = {
      id: `${nodes.length + 1}`,
      type: 'program', // Ensure this matches 'program' in nodeTypesMap
      data: { label: `Node ${nodes.length + 1}`, shape },
      position: { x: 100, y: nodes.length * 150 },
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const addEdgeDynamically = () => {
  if (nodes.length < 2) {
    alert('Add at least two nodes to create an edge.');
    return;
  }
  const newEdge = {
    id: `e${nodes.length - 1}-${nodes.length}`,
    source: `${nodes.length - 1}`,
    target: `${nodes.length}`,
    animated: true,
  };
  setEdges((eds) => {
    const updatedEdges = eds.concat(newEdge);
    console.log('Updated Edges:', updatedEdges);
    return updatedEdges;
  });
};


  const removeSelectedElements = () => {
    
    const selectedNodes = nodes.filter((node) => node.selected);
    const selectedEdges = edges.filter((edge) => edge.selected);

    if (selectedNodes.length === 0 && selectedEdges.length === 0) {
      alert('Select a node or edge to remove.');
      return;
    }
    const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
    const updatedNodes = nodes.filter((node) => !node.selected);

    const updatedEdges = edges.filter(
      (edge) =>
        !selectedEdges.includes(edge) &&
        !selectedNodeIds.has(edge.source) &&
        !selectedNodeIds.has(edge.target)
    );
  
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };
  
console.log(type);
  const nodeTypes = useMemo(
    () => ({
      ...nodeTypesMap,
      default: nodeTypesMap[type] || null, // Fallback to ProgramNode if type doesn't match
    }),
    [type]
  );

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {elements.length > 0 && type.length > 0 ? (
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            nodeTypes={nodeTypes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
          >
            <Controls />
          </ReactFlow>
        </ReactFlowProvider>
      ) : (
        <p>No flowchart data available</p>
      )}
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 9999,
          display: 'flex',
          gap: '10px',
        }}
      >
       
        <button
          onClick={() => addNode('rectangle')}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Add Rectangle Node"
        >
          <FiSquare size={20} />
        </button>
      
        <button
          onClick={() => addNode('oval')}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Add Oval Node"
        >
          <FaRegCircle size={20} />
        </button>

        <button
          onClick={() => addNode('diamond')}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Add Diamond Node"
        >
          <GoDiamond size={20} />
        </button>

        <button
          onClick={addEdgeDynamically}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Add Edge"
        >
          <FaLink size={20} />
        </button>

        <button
          onClick={removeSelectedElements}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Remove Selected"
        >
          <FaTrash size={20} />
        </button>

        <button
          onClick={saveToAPI}
          style={{
            padding: '8px',
            borderRadius: '50%',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
          title="Save Flowchart"
        >
          <FaSave size={20} />
        </button>
      </div>
    </div>
  );
};

export default Flowchart;
