import React, { useState, useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useBoardStore } from "../Store/BoardStore/BoardStore";
import { useMemberStore } from "../Store/MemberStore/MemberStore";
import { useFlowchartStore } from '../Store/FlowchartStore/FlowchartStore';
import { FaTrash } from 'react-icons/fa';

const CreateBoard = () => {
  const [drawings, setDrawings] = useState([]);
  const [member, setMember] = useState([]);
  const [charts, setCharts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [filters, setFilters] = useState({
    ownership: 'anyone',
    lastOpened: false,
    assigned: false // New filter for assigned boards
  });

  const navigate = useNavigate();
  const { createBoard, getBoard, deleteBoard } = useBoardStore();
  const { getMemberBoard, getMember } = useMemberStore();
  const { getChart, deleteChart, setSelectedChartId } = useFlowchartStore();

  const userId = localStorage.getItem("userId");

  const array = [
    { src: "/Screenshot (13).png", alt: "image1", text: "Process Flowchart", route: "/process" },
    { src: "/Screenshot (14).png", alt: "image2", text: "Program Flowchart", route: "/program" },
    { src: "/Screenshot (15).png", alt: "image3", text: "Swimlance Flowchart", route: "/swimlance" },
    { src: "/Screenshot (16).png", alt: "image4", text: "System Flowchart", route: "/system" },
    { src: "/Screenshot (17).png", alt: "image5", text: "Workflow Flowchart", route: "/workflow" },
    { src: "/Screenshot (18).png", alt: "image6", text: "EPC Flowchart", route: "/EPC" },
    { src: "/Screenshot (19).png", alt: "image7", text: "Decision Flowchart", route: "/decision" },
    { src: "/Screenshot (20).png", alt: "image8", text: "Dataflow Flowchart", route: "/dataflow" },
    { src: "/Screenshot (21).png", alt: "image9", text: "Cross Functional Flowchart", route: "/crossfuntional" },
    { src: "/Screenshot (22).png", alt: "image10", text: "BPMN Flowchart", route: "/BPMN" },
  ];

  // Function to fetch drawings by user ID
  const fetchDrawings = async () => {
    try {
      const data = await getBoard();
      setDrawings(data || []); // Ensure drawings is always an array
    } catch (error) {
      console.error("Error fetching drawings:", error);
    }
  };

  useEffect(() => {
    fetchDrawings();
  }, []);

  const fetchMembers = async () => {
    try {
      const helo = await getMemberBoard();
      console.log(helo);
      setMember(helo || [])

    } catch (err) {
      console.error("Error fetching drawings:", err);
    }
  }
  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchChart = async () => {
    try {
      const chart = await getChart();
      setCharts(chart || []); // Ensure charts is always an array
    } catch (error) {
      console.error("Error fetching flowcharts:", error);
    }
  };

  const clickedID = async () => {

  }

  useEffect(() => {
    fetchChart();
  }, []);

  const handleFilterChange = (type) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [type]: type === 'lastOpened'
        ? !prevFilters.lastOpened
        : type === 'assigned'
          ? !prevFilters.assigned
          : (prevFilters[type] === 'anyone' ? 'owned' : 'anyone')
    }));
  };


  const handleDelete = async (boardId) => {
    try {
      await deleteBoard(boardId);
      await fetchDrawings(); // Refresh the list of drawings after deletion
    } catch (error) {
      console.error("Error deleting board:", error);
    }
  };

  const handleDeleteChart = async (chartId) => {
    try {
      await deleteChart(chartId);
      await fetchChart(); // Refresh the list of drawings after deletion
    } catch (error) {
      console.error("Error deleting board:", error);
    }
  };

  const handleCreateBoard = async () => {
    const projectId = localStorage.getItem('ProjectId');
    try {
      const data = await createBoard({
        paths: [],
        user: userId,
        projectId,
        updatedBy: userId,
      });

      if (data && data._id) {
        navigate("/whiteboard/" + data._id);
        localStorage.setItem('drawingId', data._id);
      } else {
        console.error("Failed to retrieve the board ID from the response.");
      }
    } catch (error) {
      console.error("Error creating board:", error);
    }
  };

  // Safely filter drawings
  const filteredDrawings = (drawings || []).filter(drawing => {
    if (filters.ownership === 'owned' && !drawing.owned) {
      return false;
    }
    if (filters.lastOpened && !drawing.lastOpened) {
      return false;
    }
    if (filters.assigned && !drawing.assignedTo.includes(userId)) { // Check if assigned to the current user
      return false;
    }
    return true;
  });

  const filteredBoard = (member || []).filter(member => {
    if (filters.ownership === 'owned' && !member.owned) {
      return false;
    }
    console.log(member.board);
    if (filters.lastOpened && !member.lastOpened) {
      return false;
    }
    if (filters.assigned && !member.assignedTo.includes(userId)) { // Check if assigned to the current user
      return false;
    }
    return true;
  });


  // Safely filter charts
  const filteredCharts = (charts || []).filter(chart => {
    if (filters.ownership === 'owned' && !chart.owned) {
      return false;
    }
    if (filters.lastOpened && !chart.lastOpened) {
      return false;
    }
    if (filters.assigned && !chart.assignedTo.includes(userId)) { // Check if assigned to the current user
      return false;
    }
    return true;
  });


  // Handler to navigate based on the clicked template
  const handleTemplateClick = (route) => {
    if (route) {
      navigate(route);
    }
  };

  return (
    <>
      <div className="mt-4 space-y-4">
        <h1 className="create-board-title Reshidden">Create a board</h1>
        <div className="create-board-container">
          <div className="create-board-header">
            <p className="create-board-subtitle">Recommended templates</p>
            <p className="create-board-show-all" style={{ cursor: 'pointer' }} onClick={() => setShowAll(!showAll)}>
              {showAll ? "Show Less" : "Show All"}
            </p>
          </div>
          <div className="create-board-grid">
            <div className="md:mr-2">
              <div className="create-board-new">
                <div className="create-board-new-icon" onClick={handleCreateBoard}>
                  <IoMdAdd size={40} />
                </div>
              </div>
              <p className="create-board-new-text">New Board</p>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4">
              {array.slice(0, showAll ? array.length : 4).map((item, index) => (
                <div key={index} className="w-full">
                  <img
                    src={item.src}
                    alt={item.alt}
                    className="create-board-item-image create-board-item"
                    style={{ height: "142px", borderRadius: "9px", cursor: 'pointer' }}
                    onClick={() => handleTemplateClick(item.route)}
                  />
                  <p className="create-board-item-text">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="create-board-filter-container">
        <h1 className="create-board-filter-title">Boards in this team</h1>
        <div className="create-board-filter-buttons">
          <div className="create-board-filter-button" onClick={() => handleFilterChange('ownership')}>
            <span>{filters.ownership === 'anyone' ? 'Owned by anyone' : 'Owned by me'}</span>
            <div className="create-board-filter-icon">
              <MdKeyboardArrowDown size={20} />
            </div>
          </div>
          <div className="create-board-filter-button" onClick={() => handleFilterChange('lastOpened')}>
            <span>Last opened</span>
            <div className="create-board-filter-icon">
              <MdKeyboardArrowDown size={20} />
            </div>
          </div>
          <div className="create-board-filter-button" onClick={() => handleFilterChange('assigned')}>
            <span>{filters.assigned ? 'Assigned to me' : 'All boards'}</span> {/* Toggle for assigned filter */}
            <div className="create-board-filter-icon">
              <MdKeyboardArrowDown size={20} />
            </div>
          </div>
        </div>

      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 mt-4 rounded">
        {filteredDrawings.map((drawing, index) => (
          <div key={drawing._id} className="relative border border-gray-300 rounded-lg overflow-hidden">
            <Link
              to={`/whiteboard/${drawing._id}`}
              onClick={() => localStorage.setItem('drawingId', drawing._id)} // Set drawing ID in local storage
              className="flex flex-col items-center"
            >
              <img
                src={drawing.thumbnail || "/images.jpeg"}
                alt={`Drawing ${index + 1}`}
                className="w-full h-32 object-cover"
              />
              <div className="flex items-center justify-center mt-2">
                <p className=" text-xl">{drawing.name || `Drawing ${index + 1}`}</p>
                <button
                  onClick={(e) => {
                    e.preventDefault(); // Prevents navigation when clicking the delete button
                    handleDelete(drawing._id);
                  }}
                  className="mr-2 text-gray-600 hover:text-red-600"
                >
                  <FaTrash />
                </button>
              </div>
            </Link>
          </div>
        ))}
        {filteredBoard.map((member, index) => (
          <div key={member._id} className="relative border border-gray-300 rounded-lg overflow-hidden">
            <Link
              to={`/whiteboard/${member._id}`}
              // onClick={() => localStorage.setItem('drawingId', member.board)} // Set drawing ID in local storage
              className="flex flex-col items-center"
            >
              <img
                src={member.thumbnail || "/images.jpeg"}
                alt={`Drawing ${index + 1}`}
                className="w-full h-32 object-cover"
              />
              <div className="flex items-center justify-center mt-2">
                <p className=" text-xl">{member.name || `Drawing ${index + 1}`}</p>
                {/* <button
                  onClick={(e) => {
                    e.preventDefault(); // Prevents navigation when clicking the delete button
                    handleDelete(member._id);
                  }}
                  className="mr-2 text-gray-600 hover:text-red-600"
                >
                  <FaTrash />
                </button> */}
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 mt-4 rounded">
        {filteredCharts.map((chart, index) => (
          <div key={chart._id} className="relative border border-gray-300 rounded-lg overflow-hidden">
            <Link
              to={`/flowchart/${chart._id}`}
              onClick={() => localStorage.setItem('chartId', chart._id)}
              className="flex flex-col items-center"
            >
              <img
                src={chart.thumbnail || "/Screenshot (17).png"}
                alt={`Flowchart ${index + 1}`}
                className="w-full h-32 object-cover"
              />
              <div className="flex items-center justify-center mt-2">
                <p className=" text-xl">{chart.name || `Flowchart ${index + 1}`}</p>
                <button
                  onClick={(e) => {
                    e.preventDefault(); // Prevents navigation when clicking the delete button
                    handleDeleteChart(chart._id);
                  }}
                  className="mr-2 text-gray-600 hover:text-red-600"
                >
                  <FaTrash />
                </button>
              </div>
            </Link>

          </div>
        ))}
      </div>
    </>
  );
};

export default CreateBoard;
