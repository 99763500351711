import React, { useState, useEffect } from "react";
import { IoSearch } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import moment from "moment";
import MobileProjectTable from "./MobileProjectTable";
import CreateProjectModal from "./CreateProjectModal";
import EditProjectModal from "./EditProjectModel"; 
import { useProjectStore } from "../Store/ProjectStore/ProjectStore";

const Projects = () => {
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);
  const [isEditProjectOpen, setIsEditProjectOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const { getProject, deleteProject, searchProject, project } = useProjectStore();

  const handleOpenProjectModal = () => {
    setIsCreateProjectOpen(true);
  };

  const handleCloseProjectModal = () => {
    setIsCreateProjectOpen(false);
  };

  const handleOpenEditModal = (project) => {
    setSelectedProject(project);
    setIsEditProjectOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditProjectOpen(false);
    setSelectedProject(null);
  };

  const handleSaveEdit = () => {
    fetchProjects();
  };

  const fetchProjects = async () => {
    setLoading(true);
    setError("");
    try {
      await getProject();
    } catch (err) {
      setError("Failed to fetch projects. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProject(projectId);
      await fetchProjects();
    } catch (error) {
      console.error("Error deleting project", error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout = setTimeout(async () => {
      if (query) {
        try {
          await searchProject(query); 
               } catch (err) {
          console.error("Failed to search projects", err);
        }
      } else {
        fetchProjects(); 
      }
    }, 500); 

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    fetchProjects();
  }, []);
const name=localStorage.getItem('name');
  return (
    <>
      <div className="min-h-screen bg-gray-100 p-4 Reshidden">
        <div className="max-w-full mx-auto bg-white shadow-lg rounded-lg p-6">
          <header className="header-container">
            <h1 className="header-title Reshidden">Projects</h1>
            <div
              onClick={handleOpenProjectModal}
              className="create-project-button Reshidden"
            >
              <IoMdAdd size={20} />
              <button className="text-blue-600 font-semibold">
                Create Projects
              </button>
            </div>
          </header>

          <div className="search-container">
            <IoSearch size={25} />
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search Projects"
              className="search-input"
            />
          </div>

          <div className="overflow-x-auto mt-4">
            {loading ? (
              <p>Loading projects...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <table className="table">
                <thead>
                  <tr className="table-header">
                    <th className="table-header-cell">Name</th>
                    <th className="table-header-cell">Created Date</th>
                    <th className="table-header-cell">Created By</th>
                    <th className="table-header-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {project.map((projects) => (
                    <tr
                      key={projects._id}
                      className="table-row"
                    >
                      <td className="space-y-2 flex items-center" style={{ paddingTop: '1rem', paddingBottom: '1rem', alignItems: 'center' }}>
                        <div className="avatar">
                          <span className="avatar-text">
                            {projects.projectname.charAt(0)}
                          </span>
                        </div>
                        <div>
                          <p className="font-semibold">{projects.projectname}</p>
                        </div>
                      </td>
                      <td className="table-cell">{moment(projects.createdAt).format("LLL")}</td>
                      <td className="table-cell">{name}</td>
                      <td className="table-cell flex space-x-2">
                        <button
                          className="actions-button"
                          onClick={() => handleOpenEditModal(projects)}
                        >
                          <FiEdit size={20} />
                        </button>
                        <button className="delete-button" onClick={() => handleDeleteProject(projects._id)}>
                          <FiTrash2 size={20} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <CreateProjectModal
          isOpen={isCreateProjectOpen}
          onClose={handleCloseProjectModal}
          onSave={fetchProjects}
        />
        <EditProjectModal
          isOpen={isEditProjectOpen}
          onClose={handleCloseEditModal}
          project={selectedProject}
          onSave={handleSaveEdit}
        />
      </div>
      <div className="">
        <MobileProjectTable fetchProjects={fetchProjects} projects={project} handleDeleteProject={handleDeleteProject}/>
      </div>
    </>
  );
};

export default Projects;
